import { gql, useQuery } from "@apollo/client";
import Card from "../components/CardSoul";
import React, { useEffect } from "react";
import { useUserId } from "@nhost/react";
import { Helmet } from "react-helmet";

const GET_USERS = gql`
  query MyQuery {
    users(where: { phoneNumberVerified: { _eq: true } }) {
      metadata
      phoneNumberVerified
      id
      displayName
      avatarUrl
    }
  }
`;

const Souls = () => {
  const id = useUserId();
  const { loading, error, data, refetch } = useQuery(GET_USERS);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          SpiritX - Discover the Wisdom and Journey of Inspirational Individuals
        </title>
        <meta
          name="description"
          content="Discover the experiences, wins, and principles of accomplished individuals from diverse backgrounds and fields. SpiritX empowers you to unlock your potential and drive positive change."
        />
        <meta
          name="keywords"
          content="SpiritX, inspirational individuals, wisdom, journey, experiences, wins, principles, diverse backgrounds, potential, positive change"
        />
        <meta
          property="og:title"
          content="SpiritX - Discover the Wisdom and Journey of Inspirational Individuals"
        />
        <meta
          property="og:description"
          content="Discover the experiences, wins, and principles of accomplished individuals from diverse backgrounds and fields. SpiritX empowers you to unlock your potential and drive positive change."
        />
        {/* <meta
    property="og:image"
    content="https://example.com/main-page-image.jpg"
  /> */}
      </Helmet>
      <div className="flex flex-col w-full ">
        {/* <Card /> */}
        {data && <Card items={data?.users} />}
      </div>
    </>
  );
};

export default Souls;
