import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function removeHtmlTags(htmlString) {
  const div = document.createElement("div");
  div.innerHTML = htmlString;
  return div.textContent || div.innerText || "";
}

export default function SimplaeCard({ items, icon }) {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <div className="flex-auto ">
        <ul role="list" className="my-4 space-y-3 px-2 sm:px-0">
          {location.pathname.includes("souls") ? (
            ""
          ) : (
            <Link
              to={{
                pathname: `${location.pathname}/create`,
              }}
              className=" flex justify-center items-center text-gray-400 sm:flex-row w-full border-dashed border line-clamp-1 rounded-md border-gray-300 bg-white shadow-sm text-center hover:border-indigo-400 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <svg
                className="h-12 w-12 "
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  vectorEffect="non-scaling-stroke"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                />
              </svg>
              <span className="pl-2 block text-sm font-semibold 0">
                Add new
              </span>
            </Link>
          )}
          {items.map((item) => (
            <li key={item.id} className="flex rounded-md shadow-sm  ">
              <Link
                // to={{
                //   pathname: `${location.pathname}/view/${item.id}`,
                // }}
                to={{
                  pathname: location.pathname.includes("souls")
                    ? `${location.pathname}/${item.__typename}/view/${item.id}`
                    : `${location.pathname}/view/${item.id}`,
                }}
                className={classNames(
                  item.color,
                  "flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white"
                )}
              >
                <img
                  className="h-6 text-white w-auto"
                  src={icon}
                  alt="Spirit X"
                />
              </Link>

              <Link
                // to={{
                //   pathname: `${location.pathname}/view/${item.id}`,
                // }}
                to={{
                  pathname: location.pathname.includes("souls")
                    ? `${location.pathname}/${item?.__typename}/view/${item.id}`
                    : `${location.pathname}/view/${item.id}`,
                }}
                className="flex flex-auto items-center justify-between line-clamp-1 rounded-r-md border-b border-r border-t border-gray-200 bg-white hover:bg-indigo-50 "
              >
                <div className="line-clamp-1 px-4 sm:px-6 py-2 text-sm ">
                  <div className="font-medium text-md sm:text-lg text-gray-900 line-clamp-2">
                    {item.title}
                    <p className="text-gray-500 text-sm line-clamp-1 font-normal ">
                      {removeHtmlTags(item.description)}
                    </p>
                    {/* <p className="text-gray-400  text-sm pt-2 line-clamp-1 font-normal ">
                      {item.privacy}
                    </p> */}
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
