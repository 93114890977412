import { ArrowLeftIcon, PencilIcon, PlusIcon } from "@heroicons/react/solid";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useState, useEffect } from "react";
import { TypographyStylesProvider } from "@mantine/core";
import { useOutletContext } from "react-router-dom";
import mixpanel from "mixpanel-browser";

const GET_WIN_QUERY = gql`
  query MyQuery($id: uuid!) {
    wins_by_pk(id: $id) {
      color
      created_at
      date
      deleted
      description
      id
      privacy
      soul_id
      title
      updated_at
    }
  }
`;

const GET_USER_QUERY = gql`
  query GetUser($id: uuid!) {
    user(id: $id) {
      id
      email
      displayName
      metadata
      avatarUrl
    }
  }
`;

const GET_PRINCIPES_QUERY = gql`
  query MyQuery($id: uuid!) {
    principles_by_pk(id: $id) {
      color
      created_at
      date
      deleted
      description
      id
      privacy
      soul_id
      title
      updated_at
    }
  }
`;

const GET_INSIGHTS_QUERY = gql`
  query MyQuery($id: uuid!) {
    insights_by_pk(id: $id) {
      color
      created_at
      date
      deleted
      description
      id
      privacy
      soul_id
      title
      updated_at
    }
  }
`;

const GET_ACTIVITIES_QUERY = gql`
  query MyQuery($id: uuid!) {
    activities_by_pk(id: $id) {
      color
      created_at
      date
      deleted
      description
      scope
      id
      privacy
      soul_id
      title
      updated_at
    }
  }
`;

const DELETE_WIN_MUTATION = gql`
  mutation MyMutation($id: uuid!) {
    update_wins_by_pk(pk_columns: { id: $id }, _set: { deleted: true }) {
      id
      soul_id
    }
  }
`;

const DELETE_PRINCIPLE_MUTATION = gql`
  mutation MyMutation($id: uuid!) {
    update_principles_by_pk(pk_columns: { id: $id }, _set: { deleted: true }) {
      id
      soul_id
    }
  }
`;

const DELETE_INSIGHT_MUTATION = gql`
  mutation MyMutation($id: uuid!) {
    update_insights_by_pk(pk_columns: { id: $id }, _set: { deleted: true }) {
      id
      soul_id
    }
  }
`;

const DELETE_ACTIVITY_MUTATION = gql`
  mutation MyMutation($id: uuid!) {
    update_activities_by_pk(pk_columns: { id: $id }, _set: { deleted: true }) {
      id
      soul_id
    }
  }
`;

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SimpleCardDetailes() {
  const { user } = useOutletContext();
  const [deleted, setDeleted] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [typeShort, setTypeShort] = useState("");
  const [privacy, setPrivacy] = useState("");
  const [creatorId, setCreatorId] = useState("");
  const [color, setColor] = useState("");
  const [activityType, setActivityType] = useState("");
  const [date, setDate] = useState("");
  const [dateTitle, setDateTitle] = useState("");
  const location = useLocation();
  const parts = location.pathname.split("/");
  const newLocation = parts.slice(0, 3).join("/");
  const newLocationTwo = parts.slice(0, 4).join("/");
  const navigate = useNavigate();
  const getObjectId = useParams();
  const soulId = getObjectId.soulId;
  const id = getObjectId.id || user.id;

  let query;
  if (location.pathname.includes("win")) {
    query = GET_WIN_QUERY;
  } else if (location.pathname.includes("principles")) {
    query = GET_PRINCIPES_QUERY;
  } else if (location.pathname.includes("insights")) {
    query = GET_INSIGHTS_QUERY;
  } else if (location.pathname.includes("activities")) {
    query = GET_ACTIVITIES_QUERY;
  } else if (location.pathname.includes("about")) {
    query = GET_USER_QUERY;
  } else if (location.pathname.includes("souls")) {
    query = GET_USER_QUERY;
  }

  const { loading, error, data } = useQuery(query, {
    variables: { id: id },
    skip: !id,
  });

  useEffect(() => {
    if (loading || error) {
      return;
    }
    if (data?.wins_by_pk) {
      setTitle(data?.wins_by_pk.title);
      setDescription(data?.wins_by_pk.description);
      setType(data?.wins_by_pk.__typename);
      setPrivacy(data?.wins_by_pk.privacy);
      setColor(data?.wins_by_pk.color);
      setDate(data?.wins_by_pk.date);
      setCreatorId(data?.wins_by_pk.soul_id);
      setDateTitle("Date of win");
      setTypeShort("Win");
    } else if (data?.principles_by_pk) {
      setTitle(data?.principles_by_pk.title);
      setDescription(data?.principles_by_pk.description);
      setType(data?.principles_by_pk.__typename);
      setPrivacy(data?.principles_by_pk.privacy);
      setColor(data?.principles_by_pk.color);
      setCreatorId(data?.principles_by_pk.soul_id);
      setDate(data?.principles_by_pk.date);
      setDateTitle("Effective date");
      setTypeShort("Principle");
    } else if (data?.insights_by_pk) {
      setTitle(data?.insights_by_pk.title);
      setDescription(data?.insights_by_pk.description);
      setType(data?.insights_by_pk.__typename);
      setPrivacy(data?.insights_by_pk.privacy);
      setColor(data?.insights_by_pk.color);
      setCreatorId(data?.insights_by_pk.soul_id);
      setDate(data?.insights_by_pk.date);
      setDateTitle("Effective date");
      setTypeShort("Insights");
    } else if (data?.activities_by_pk) {
      setTitle(data?.activities_by_pk.title);
      setDescription(data?.activities_by_pk.description);
      setType(data?.activities_by_pk.__typename);
      setPrivacy(data?.activities_by_pk.privacy);
      setCreatorId(data?.activities_by_pk.soul_id);
      setColor(data?.activities_by_pk.color);
      setDate(data?.activities_by_pk.date);
      setActivityType(data?.activities_by_pk.scope);
      setDateTitle("Effective date");
      setTypeShort("Activities");
    } else if (
      location.pathname.includes("about") ||
      location.pathname.includes("souls")
    ) {
      // setTitle(data?.principles_by_pk.title);
      setDescription(data?.user?.metadata?.description || "No description...");
      setType("about");
      setCreatorId(data?.user?.id);
      // setPrivacy(data?.principles_by_pk.privacy);
      // setColor(data?.principles_by_pk.color);
      // setDate(data?.principles_by_pk.date);
      // setDateTitle("Effective date");
      // setTypeShort("Principle");
    }
  }, [data, loading, error, user]);

  const [removeObject, { loading: deletingObject }] = useMutation(
    data?.wins_by_pk
      ? DELETE_WIN_MUTATION
      : data?.principles_by_pk
      ? DELETE_PRINCIPLE_MUTATION
      : data?.insights_by_pk
      ? DELETE_INSIGHT_MUTATION
      : data?.activities_by_pk
      ? DELETE_ACTIVITY_MUTATION
      : DELETE_ACTIVITY_MUTATION
  );

  const deleteObject = async (e) => {
    e.preventDefault();

    try {
      await removeObject({
        variables: {
          id: id,
          soul_id: creatorId,
        },
        refetchQueries: [
          { query: query, variables: { id: id } },
          // Add any other queries that need to be refetched
        ],
      });
      toast.success("Deleted successfully", { id: "deleteObject" });
      navigate(-1);
    } catch (error) {
      // console.log(error);
      toast.error("Unable to delete the object", { id: "deleteObject" });
    }
  };

  // Mixpanel start

  const {
    loading: loading2,
    error: error2,
    data: data2,
  } = useQuery(GET_USER_QUERY, {
    variables: { id: creatorId },
    skip: !creatorId,
  });

  useEffect(() => {
    // Check if both user data and data2 are loaded
    if (user && data2 && data) {
      const soulFullName = `${user?.metadata?.firstName} ${user?.metadata?.lastName}`;
      const soulToSeeFullName = `${data2?.user?.metadata?.firstName} ${data2?.user?.metadata?.lastName}`;

      mixpanel.track("Simple Card Visit", {
        page: typeShort,
        user_id: user?.id,
        user_full_name: soulFullName,
        creator_id: soulId,
        creator_full_name: soulToSeeFullName,
        card_id: id,
        card_title: title,
      });
    }
  }, [user, data2, data]);

  // Mixpanel end

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-white px-2 sm:px-6 py-2 sm:py-6 lg:px-8 sm:border border-gray-100 rounded-md  my-4 w-full max-w-4xl mx-auto">
      <div className="mx-auto max-w-2xl text-base leading-7 text-gray-700">
        {location.pathname.includes("souls") ? (
          <div className="flex flex-wrap items-center gap-2  sm:flex-nowrap pb-8">
            <Link
              to={{
                // pathname: `${newLocation}/${type}`,
                pathname: `${newLocation}`,
              }}
              className="flex items-center gap-x-1 rounded-md border border-gray-100 px-3 py-2 text-sm font-normal text-gray-500 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
            >
              <ArrowLeftIcon className="-ml-1.5 h-5 w-5" aria-hidden="true" />
              Back
            </Link>
          </div>
        ) : location.pathname.includes("about") ? (
          <div className="flex">
            <Link
              to={{
                pathname: `${newLocation}/edit`,
              }}
              className="ml-auto flex items-center gap-x-1 rounded-md border border-gray-100 px-3 py-2 text-sm font-normal text-gray-500 shadow-sm hover:bg-indigo-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <PencilIcon className="-ml-1.5 h-5 w-5" aria-hidden="true" />
              Edit
            </Link>
          </div>
        ) : (
          <div className="flex flex-wrap items-center gap-2  sm:flex-nowrap pb-8">
            <Link
              to={{
                // pathname: `${newLocation}/${type}`,
                pathname: `${newLocation}`,
              }}
              className="flex items-center gap-x-1 rounded-md border border-gray-100 px-3 py-2 text-sm font-normal text-gray-500 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
            >
              <ArrowLeftIcon className="-ml-1.5 h-5 w-5" aria-hidden="true" />
              Back
            </Link>
            <Link
              to={{
                pathname: `${newLocation}/edit/${id}`,
              }}
              className="ml-auto flex items-center gap-x-1 rounded-md border border-gray-100 px-3 py-2 text-sm font-normal text-gray-500 shadow-sm hover:bg-indigo-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <PencilIcon className="-ml-1.5 h-5 w-5" aria-hidden="true" />
              Edit
            </Link>
            <form onSubmit={deleteObject}>
              <button
                type="submit"
                className="ml-auto flex items-center gap-x-1 rounded-md border bg-red-400 border-gray-100 px-3 py-2 text-sm font-normal text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Delete
              </button>
            </form>
          </div>
        )}
        {location.pathname.includes("about") ? (
          ""
        ) : (
          <div>
            <p className="text-base font-semibold leading-7 text-indigo-600">
              {typeShort}{" "}
              <span className="text-yellow-600 font-bold">{activityType}</span>
            </p>
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl pb-10">
              {title ? title : ""}
            </h1>
          </div>
        )}
        <div className="max-w-2xl prose prose-lg lg:prose-xl pt-4 ">
          <TypographyStylesProvider>
            <div
              dangerouslySetInnerHTML={{ __html: description }}
              className="prose lg:prose-lg"
            />
          </TypographyStylesProvider>
        </div>
        {location.pathname.includes("about") ? (
          ""
        ) : (
          <div>
            {" "}
            <figcaption className="mt-10 flex gap-x-2 text-sm leading-6 text-gray-500">
              <span className="font-semibold">Status:</span> {privacy}
            </figcaption>
            <figcaption className="mt-2 flex gap-x-2 text-sm leading-6 text-gray-500">
              <span className="font-semibold">{dateTitle}:</span> {date}
            </figcaption>
          </div>
        )}
      </div>
    </div>
  );
}
