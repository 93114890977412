import React from "react";
import {
  getPublicUrl,
  NhostProvider,
  NhostClient,
  useNhostClient,
} from "@nhost/react";

import { Link, useLocation, useNavigate } from "react-router-dom";

export default function Card({ items }) {
  const location = useLocation();
  const navigate = useNavigate();
  const nhost = new NhostClient({
    backendUrl: "https://upxymbcutmxglgmlifxj.nhost.run",
  });

  return (
    <ul
      role="list"
      className="grid grid-cols-1 gap-x-2 gap-y-6 sm:grid-cols-2 sm:gap-x-4 lg:grid-cols-4 xl:gap-x-4 pt-4 pb-6 "
    >
      {location.pathname.includes("souls") ? (
        ""
      ) : (
        <Link
          className="flex justify-center items-center text-gray-400 sm:flex-row w-full border-dashed border line-clamp-1 rounded-md border-gray-300 bg-white shadow-sm text-center hover:border-indigo-400 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          to={{
            pathname: `${location.pathname}/create`,
          }}
        >
          <svg
            className="h-12 w-12 "
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              vectorEffect="non-scaling-stroke"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
          </svg>
          <span className="pl-2 block text-sm font-semibold 0">Add new</span>
        </Link>
      )}
      {items.map((item) => (
        <li key={item.id} className="relative">
          <Link
            // to={{
            //   pathname: `${location.pathname}/view/${item.id}`,
            // }}
            to={{
              pathname: location.pathname.includes("souls")
                ? `${location.pathname}/${item.__typename}/view/${item.id}`
                : `${location.pathname}/view/${item.id}`,
            }}
          >
            {item?.__typename === "motivators" ? (
              <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                <img
                  src={nhost.storage.getPublicUrl({
                    fileId: `${item.file.id}`,
                  })}
                  alt=""
                  className="pointer-events-none object-cover group-hover:opacity-75"
                />
                <button
                  type="button"
                  className="absolute inset-0 focus:outline-none"
                >
                  <span className="sr-only">View details for {item.title}</span>
                </button>
              </div>
            ) : (
              <div className="group aspect-h-10 aspect-w-7 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                <img
                  src={nhost.storage.getPublicUrl({
                    fileId: `${item.file.id}`,
                  })}
                  alt=""
                  className="pointer-events-none object-cover group-hover:opacity-75"
                />
                <button
                  type="button"
                  className="absolute inset-0 focus:outline-none"
                >
                  <span className="sr-only">View details for {item.title}</span>
                </button>
              </div>
            )}

            <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">
              {item?.__typename === "motivators" ? <div>{item.title}</div> : ""}
              {/* {item.title} */}
            </p>
            <p className="pointer-events-none block text-xs truncate font-normal text-gray-500">
              {item.short_description}
            </p>
          </Link>
        </li>
      ))}
    </ul>
  );
}
