import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { NhostClient, NhostProvider } from "@nhost/react";
import ProtectedRoute from "./components/ProtectedRoute";
import { NhostApolloProvider } from "@nhost/react-apollo";
import { Navigate } from "react-router-dom";
import { useEffect } from "react";
import Layout from "./components/Layout";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Profile from "./pages/Profile";
import Soul from "./pages/Soul";
import SoulView from "./pages/SoulView";
import Wins from "./pages/Wins";
import Principles from "./pages/Principles";
import Insights from "./pages/Insights";
import Activities from "./pages/Activities";
import Motivators from "./pages/Motivators";
import Books from "./pages/Books";
import About from "./pages/About";
import Souls from "./pages/Souls";
import SimpleCardDetails from "./components/SimpleCardDetailes";
import CardDetails from "./components/CardDetailes";
import SimpleCardDetailsEdit from "./components/SimpleCardDetailesEdit";
import CardDetailsEdit from "./components/CardDetailesEdit";
import mixpanel from "mixpanel-browser";

import People from "./pages/People";

const nhost = new NhostClient({
  subdomain: process.env.REACT_APP_NHOST_SUBDOMAIN,
  region: process.env.REACT_APP_NHOST_REGION,
});

function App({ soulId }) {
  useEffect(() => {
    mixpanel.init("eb8b1f7ec4bccaa7199f4cfbbf374ee0", { debug: false });
    // mixpanel.init(process.env.MIXPANEL_TOKEN, { debug: false });
  }, []);
  return (
    <>
      <NhostProvider nhost={nhost}>
        <NhostApolloProvider nhost={nhost}>
          <BrowserRouter>
            <Routes>
              <Route path="sign-up" element={<SignUp />} />
              <Route path="sign-in" element={<SignIn />} />
              <Route
                path="/"
                // highlight-start
                element={
                  <ProtectedRoute>
                    <Layout />
                  </ProtectedRoute>
                }
                // highlight-end
              >
                <Route
                  path=""
                  element={
                    <Navigate
                      to={{
                        pathname: "/soul/about",
                      }}
                    />
                  }
                ></Route>
                <Route path="soul" element={<Soul />}>
                  <Route path="about" element={<About />} />
                  <Route
                    path="about/edit"
                    element={<SimpleCardDetailsEdit />}
                  />
                  <Route path="wins" element={<Wins />} />
                  <Route path="wins/view/:id" element={<SimpleCardDetails />} />
                  <Route
                    path="wins/create"
                    element={<SimpleCardDetailsEdit />}
                  />
                  <Route
                    path="wins/edit/:id"
                    element={<SimpleCardDetailsEdit />}
                  />
                  <Route path="principles" element={<Principles />} />
                  <Route
                    path="principles/view/:id"
                    element={<SimpleCardDetails />}
                  />
                  <Route
                    path="principles/edit/:id"
                    element={<SimpleCardDetailsEdit />}
                  />
                  <Route
                    path="principles/create"
                    element={<SimpleCardDetailsEdit />}
                  />

                  <Route path="insights" element={<Insights />} />
                  <Route
                    path="insights/view/:id"
                    element={<SimpleCardDetails />}
                  />
                  <Route
                    path="insights/edit/:id"
                    element={<SimpleCardDetailsEdit />}
                  />
                  <Route
                    path="insights/create"
                    element={<SimpleCardDetailsEdit />}
                  />

                  <Route path="activities" element={<Activities />} />
                  <Route
                    path="activities/view/:id"
                    element={<SimpleCardDetails />}
                  />
                  <Route
                    path="activities/edit/:id"
                    element={<SimpleCardDetailsEdit />}
                  />
                  <Route
                    path="activities/create"
                    element={<SimpleCardDetailsEdit />}
                  />

                  <Route path="motivators" element={<Motivators />} />
                  <Route path="motivators/view/:id" element={<CardDetails />} />
                  <Route
                    path="motivators/create"
                    element={<CardDetailsEdit />}
                  />
                  <Route
                    path="motivators/edit/:id"
                    element={<CardDetailsEdit />}
                  />

                  <Route path="books" element={<Books />} />
                  <Route path="books/view/:id" element={<CardDetails />} />
                  <Route path="books/create" element={<CardDetailsEdit />} />
                  <Route path="books/edit/:id" element={<CardDetailsEdit />} />
                </Route>
                <Route path="souls" element={<Souls />}></Route>
                <Route path="souls/:id" element={<SoulView />} />
                <Route path="souls/:id/about" element={<SimpleCardDetails />} />
                <Route
                  path="souls/:id/books/view/:id"
                  element={<CardDetails />}
                />
                <Route
                  path="souls/:id/motivators/view/:id"
                  element={<CardDetails />}
                />
                <Route
                  path="souls/:id/:id/view/:id"
                  element={<SimpleCardDetails />}
                />

                <Route path="people" element={<People />} />
                <Route path="settings" element={<Profile />} />
              </Route>
            </Routes>
          </BrowserRouter>

          <Toaster />
        </NhostApolloProvider>
      </NhostProvider>
    </>
  );
}

export default App;
