import styles from "../styles/components/SignUp.module.css";
import { useState } from "react";
import { useSignUpEmailPassword } from "@nhost/react";
import { Link, Navigate } from "react-router-dom";
import Input from "./Input";
import Spinner from "./Spinner";
import { Helmet } from "react-helmet";

const SignUp = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const {
    signUpEmailPassword,
    isLoading,
    isSuccess,
    needsEmailVerification,
    isError,
    error,
  } = useSignUpEmailPassword();

  const handleOnSubmit = (e) => {
    e.preventDefault();

    signUpEmailPassword(email, password, {
      displayName: `${firstName} ${lastName}`.trim(),
      metadata: {
        firstName,
        lastName,
        profileActive: false,
      },
    });
  };

  if (isSuccess) {
    return <Navigate to="/" replace={true} />;
  }

  const disableForm = isLoading || needsEmailVerification;

  return (
    <>
      <Helmet>
        <title>Sign Up - SpiritX</title>
        <meta
          name="description"
          content="Sign up for SpiritX and join a community of inspirational individuals. Start your journey towards personal growth and positive impact."
        />
        <meta
          name="keywords"
          content="sign up, register, SpiritX, inspirational individuals, community, personal growth, positive impact"
        />
        <meta property="og:title" content="Sign Up - SpiritX" />
        <meta
          property="og:description"
          content="Sign up for SpiritX and join a community of inspirational individuals. Start your journey towards personal growth and positive impact."
        />
        {/* <meta
    property="og:image"
    content="https://example.com/sign-up-image.jpg"
  /> */}
      </Helmet>
      <div className={styles.container}>
        <div className={styles.card}>
          <div className={styles["logo-wrapper"]}>
            <img src={process.env.PUBLIC_URL + "logo.svg"} alt="logo" />
          </div>

          {needsEmailVerification ? (
            <p className={styles["verification-text"]}>
              Please check your mailbox and follow the verification link to
              verify your email.
            </p>
          ) : (
            <form onSubmit={handleOnSubmit} className={styles.form}>
              <div className={styles["input-group"]}>
                <Input
                  label="First name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  disabled={disableForm}
                  required
                />
                <Input
                  label="Last name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  disabled={disableForm}
                  required
                />
              </div>
              <Input
                type="email"
                label="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={disableForm}
                required
              />
              <Input
                type="password"
                label="Create password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                disabled={disableForm}
                required
              />

              <button
                type="submit"
                disabled={disableForm}
                className={styles.button}
              >
                {isLoading ? <Spinner size="sm" /> : "Create account"}
              </button>

              {isError ? (
                <p className={styles["error-text"]}>{error?.message}</p>
              ) : null}
            </form>
          )}
        </div>

        <p className={styles.text}>
          Already have an account?{" "}
          <Link to="/sign-in" className={styles.link}>
            Sign in
          </Link>
        </p>
      </div>
    </>
  );
};

export default SignUp;
