import { gql, useQuery } from "@apollo/client";
import SimpleCard from "../components/SimpleCard";
import React, { useEffect } from "react";
import { useUserId } from "@nhost/react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

const GET_INSIGHTS_QUERY = gql`
  query MyQuery($id: uuid!) {
    insights(
      order_by: { updated_at: desc }
      where: { soul_id: { _eq: $id }, deleted: { _eq: false } }
    ) {
      updated_at
      title
      soul_id
      privacy
      id
      description
      deleted
      date
      created_at
      color
    }
  }
`;

const icon = "/light.svg";

const Insights = () => {
  const id = useUserId();
  const { loading, error, data, refetch } = useQuery(GET_INSIGHTS_QUERY, {
    variables: { id: id },
    skip: !{ id: id },
  });

  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <Helmet>
        <title>Insights</title>
      </Helmet>
      <div className="flex">
        {data && (
          <SimpleCard
            items={data?.insights}
            icon={icon}
            className="bg-green-400"
          />
        )}
      </div>
    </>
  );
};

export default Insights;
