import {
  ReplyIcon,
  ThumbUpIcon,
  PhotographIcon,
  UserIcon,
} from "@heroicons/react/solid";
import { useState } from "react";
import Tabs from "../components/Tabs";
import { useOutletContext } from "react-router-dom";
import { Link, useLocation, useNavigate } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ProfileHeader({}) {
  const { user } = useOutletContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("About"); // Track the selected type
  const tabs = [
    {
      name: "About",
      href: `about`,
      current: true,
      selected: location.pathname.includes("/about"),
    },
    {
      name: "Insights",
      href: "insights",
      current: false,
      selected: location.pathname.includes("/insights"),
    },
    // {
    //   name: "Interests",
    //   href: "interests",
    //   current: false,
    //   selected: location.pathname.includes("/interests"),
    // },
    {
      name: "Books",
      href: "books",
      current: false,
      selected: location.pathname.includes("/books"),
    },
    {
      name: "Activities",
      href: "activities",
      current: false,
      selected: location.pathname.includes("/activities"),
    },
    {
      name: "Motivators",
      href: "motivators",
      current: false,
      selected: location.pathname.includes("/motivators"),
    },
    {
      name: "Wins",
      href: `wins`,
      current: false,
      selected: location.pathname.includes("/wins"),
    },
    {
      name: "Principles",
      href: "principles",
      current: false,
      selected: location.pathname.includes("/principles"),
    },
  ];

  return (
    <>
      <div className="">
        <div className="">
          {user?.metadata.backgroundImage ? (
            <img
              className="h-32 w-full object-cover lg:h-48 rounded-md"
              src={user?.metadata.backgroundImage}
              alt=""
            />
          ) : (
            <div
              className="mt-2 flex justify-center rounded-lg border
              border-dashed border-gray-900/25 px-6 py-10 bg-gray-200"
            >
              <div className="text-center">
                <PhotographIcon
                  className="mx-auto h-12 w-12 text-gray-300"
                  aria-hidden="true"
                />
                <Link
                  to={"/settings"}
                  className="mt-4 flex text-sm leading-6 text-gray-600"
                >
                  <label
                    htmlFor="file-upload"
                    className="relative cursor-pointer rounded-md  font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                  >
                    <span>
                      Provide a link to an image in your profile settings
                    </span>
                  </label>
                </Link>
              </div>
            </div>
          )}
          {/* <img
            className="h-32 w-full object-cover lg:h-48 rounded-md"
            src={user?.metadata.backgroundImage}
            alt=""
          /> */}
        </div>
        <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8 flex">
          <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
            <div className="flex">
              {/* {user?.avatarUrl == null ? ( */}
              <img
                className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32"
                src={user?.avatarUrl}
                alt=""
              />
              {/* ) : (
                <UserIcon
                  className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32 bg-indigo-200 px-4 text-white"
                  src={user?.avatarUrl}
                  alt=""
                />
              )} */}
            </div>
            <div className="mt-2 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
              <div className="mt-2 min-w-0 flex-1 sm:hidden md:block ">
                <h1 className="truncate text-2xl font-bold text-gray-900">
                  {user?.metadata.firstName} {user?.metadata.lastName}
                </h1>
                <span className="text-sm sm:text-md  text-gray-500">
                  {user?.metadata?.about}
                </span>
              </div>

              {/* <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-x-4 sm:space-y-0">
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  <ReplyIcon
                    className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span>Message</span>
                </button>
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  <ThumbUpIcon
                    className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span>Call</span>
                </button>
              </div> */}
            </div>
          </div>
          <div className="mt-6 hidden min-w-0 flex-1 sm:block md:hidden">
            <h1 className="truncate text-2xl font-bold text-gray-900">
              {user?.metadata.firstName} {user?.metadata.lastName}
            </h1>
            <span className="truncate text-md text-gray-500">
              {user?.email}
            </span>
          </div>
        </div>
        {location.pathname.includes("/souls") ? (
          <div></div>
        ) : (
          <div className="pt-4">
            <Tabs tabs={tabs} setSelectedTab={setSelectedTab} />
          </div>
        )}
      </div>
    </>
  );
}
