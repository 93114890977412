import ProfileHeader from "../components/ProfileHeader";
import React from "react";
import { gql, useQuery } from "@apollo/client";
import { Outlet, useParams } from "react-router-dom";
import SoulList from "../components/SoulList";
import { useUserId } from "@nhost/react";
import SimpleCard from "../components/SimpleCard";
import Card from "../components/Card";
import { TypographyStylesProvider } from "@mantine/core";
import { PhotographIcon } from "@heroicons/react/solid";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NoRecords from "../components/NoRecords";
import Helmet from "react-helmet";
import { useEffect } from "react";
import mixpanel from "mixpanel-browser";

const GET_USER_QUERY = gql`
  query GetUser($id: uuid!) {
    user(id: $id) {
      id
      email
      displayName
      metadata
      avatarUrl
    }
  }
`;

const GET_3_OBJECTS = gql`
  query MyQuery($id: uuid!) {
    user(id: $id) {
      id
      email
      displayName
      metadata
      avatarUrl
      wins(
        where: { deleted: { _eq: false }, privacy: { _eq: "Public" } }
        order_by: { updated_at: desc }
      ) {
        id
        title
        color
        description
      }

      activities(
        where: { deleted: { _eq: false }, privacy: { _eq: "Public" } }
        order_by: { updated_at: desc }
      ) {
        id
        title
        color
        description
      }
      principles(
        where: { deleted: { _eq: false }, privacy: { _eq: "Public" } }
        order_by: { updated_at: desc }
      ) {
        id
        title
        color
        description
      }
      insights(
        where: { deleted: { _eq: false }, privacy: { _eq: "Public" } }
        order_by: { updated_at: desc }
      ) {
        id
        title
        color
        description
      }
      books(
        where: { deleted: { _eq: false }, privacy: { _eq: "Public" } }
        order_by: { updated_at: desc }
      ) {
        id
        title
        description
        file {
          id
        }
      }
      motivators(
        where: { deleted: { _eq: false }, privacy: { _eq: "Public" } }
        order_by: { updated_at: desc }
      ) {
        id
        title
        description
        file {
          id
        }
      }
    }
  }
`;

const iconWin = "/trophy.svg";
const iconPrinciple = "/scale.svg";
const iconInsight = "/light.svg";
const iconStar = "/star.svg";

export default function SoulView() {
  const userId = useUserId();
  const location = useLocation();
  const navigate = useNavigate();
  const soulToView = useParams();
  const id = soulToView?.id;
  const { loading, error, data } = useQuery(GET_3_OBJECTS, {
    variables: { id: id },
    skip: !{ id: id },
  });
  const user = data?.user;

  const soulToSeeFullName = `${data?.user?.metadata.firstName} ${data?.user?.metadata.lastName}`;

  const {
    loading: loading2,
    error: error2,
    data: data2,
  } = useQuery(GET_USER_QUERY, {
    variables: { id: userId },
    skip: !{ id: userId },
  });

  const soulFullName = `${data2?.user?.metadata?.firstName} ${data2?.user?.metadata?.lastName}`;

  // const {
  //   loading: loading2,
  //   error: error2,
  //   data: data2,
  // } = useQuery(GET_3_OBJECTS, {
  //   variables: { id: id },
  //   skip: !{ id: id },
  // });
  // const objects = data2;

  useEffect(() => {
    // Check if both user data and data2 are loaded
    if (data2 && data) {
      mixpanel.track("Soul Visit", {
        page: "View the soul",
        user_id: userId,
        user_full_name: soulFullName,
        creator_id: id,
        creator_full_name: soulToSeeFullName,
      });
    }
  }, [data, data2]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  if (!data) {
    return <p>No data available</p>;
  }

  return (
    <>
      <Helmet>
        <title>
          Soul {user?.metadata.firstName} {user?.metadata.lastName}
        </title>
      </Helmet>
      <div className="mx-auto w-full max-w-3xl grow lg:flex px-2 sm:px-0 flex flex-row ">
        <div className="flex-1 flex flex-col">
          <div className="">
            <div>
              {user?.metadata.backgroundImage ? (
                <img
                  className="h-32 w-full object-cover lg:h-48 rounded-md"
                  src={user?.metadata.backgroundImage}
                  alt=""
                />
              ) : (
                <div
                  className="mt-2 flex justify-center rounded-lg border
              border-dashed border-gray-900/25 px-6 py-10"
                >
                  <div className="text-center">
                    <PhotographIcon
                      className="mx-auto h-12 w-12 text-gray-300"
                      aria-hidden="true"
                    />
                    <div className="mt-4 flex text-sm leading-6 text-gray-600">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer rounded-md  font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                      >
                        <span>
                          Provide a link to an image in your profile settings
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              )}
              {/* <img
            className="h-32 w-full object-cover lg:h-48 rounded-md"
            src={user?.metadata.backgroundImage}
            alt=""
          /> */}
            </div>
            <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
              <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
                <div className="flex">
                  <img
                    className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32"
                    src={user?.avatarUrl}
                    alt=""
                  />
                </div>
                <div className="mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                  <div className="mt-6 min-w-0 flex-1 sm:hidden md:block">
                    <h1 className="truncate text-2xl font-bold text-gray-900">
                      {user?.metadata.firstName} {user?.metadata.lastName}
                    </h1>
                    <span className="truncate text-md  text-gray-500">
                      {user?.email}
                    </span>
                  </div>
                </div>
              </div>
              <div className="mt-6 hidden min-w-0 flex-1 sm:block md:hidden">
                <h1 className="truncate text-2xl font-bold text-gray-900">
                  {user?.metadata.firstName} {user?.metadata.lastName}
                </h1>
                <span className="truncate text-md text-gray-500">
                  {user?.email}
                </span>
              </div>
            </div>
          </div>
          {/* About */}
          <div className=" border-b py-4 ">
            <div className="flex flex-row justify-between text-sm font-semibold text-gray-600">
              <div className="flex w-full pb-2 ">About</div>
            </div>
            <Link
              to={{
                pathname: `${location.pathname}/about`,
              }}
            >
              {data?.user?.metadata?.description ? (
                <div className="max-w-3xl prose lg:prose-x bg-white w-full  px-4 py-2 sm:px-8 sm:py-6 border border-gray-200 rounded-md shadow-sm hover:bg-indigo-50">
                  <TypographyStylesProvider>
                    <div
                      className="line-clamp-6 "
                      dangerouslySetInnerHTML={{
                        __html: data?.user?.metadata?.description,
                      }}
                    />
                  </TypographyStylesProvider>
                  <div className="pt-2 sm:pt-4 text-blue-600 font-semibold text-sm">
                    Read more
                  </div>
                </div>
              ) : (
                <NoRecords />
              )}
            </Link>
          </div>
          {/* Insights */}
          <div className=" border-b pt-2 my-2">
            <div className="flex flex-row justify-between text-sm font-semibold text-gray-600">
              <div className="flex w-full ">Insights</div>
            </div>
            {data.user.insights.length === 0 ? (
              <NoRecords />
            ) : (
              <SimpleCard
                items={data?.user?.insights}
                icon={iconInsight}
                className="bg-yellow-400"
              />
            )}
          </div>

          {/* Activities */}
          <div className=" border-b my-2">
            <div className="flex flex-row justify-between text-sm font-semibold text-gray-600">
              <div className="flex w-full ">Activities</div>
            </div>
            {data.user.activities.length === 0 ? (
              <NoRecords />
            ) : (
              <SimpleCard
                items={data.user.activities}
                icon={iconStar}
                className="bg-yellow-400"
              />
            )}
          </div>
          {/* Motivators */}
          <div className=" border-b my-2">
            <div className="flex flex-row justify-between text-sm font-semibold text-gray-600">
              <div className="flex w-full ">Motivators</div>
            </div>
            {data.user.motivators.length === 0 ? (
              <NoRecords />
            ) : (
              <Card items={data?.user?.motivators} className="bg-yellow-400" />
            )}
          </div>
          {/* Wins */}
          <div className=" border-b my-2">
            <div className="flex flex-row justify-between text-sm font-semibold text-gray-600">
              <div className="flex w-full ">Wins</div>
            </div>
            {data.user.wins.length === 0 ? (
              <NoRecords />
            ) : (
              <SimpleCard
                items={data?.user?.wins}
                icon={iconWin}
                className="bg-yellow-400"
              />
            )}
          </div>
          {/* Principles */}
          <div className=" border-b my-2">
            <div className="flex flex-row justify-between text-sm font-semibold text-gray-600">
              <div className="flex w-full ">Principles</div>
            </div>
            {data.user.principles.length === 0 ? (
              <NoRecords />
            ) : (
              <SimpleCard
                items={data?.user?.principles}
                icon={iconPrinciple}
                className="bg-yellow-400"
              />
            )}
          </div>
          {/* Books */}
          <div className=" my-2">
            <div className="flex flex-row justify-between text-sm font-semibold text-gray-600">
              <div className="flex w-full ">Books</div>
            </div>
            {data.user.books.length === 0 ? (
              <NoRecords />
            ) : (
              <Card items={data?.user?.books} className="bg-yellow-400" />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
