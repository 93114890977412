import {
  ArrowLeftIcon,
  PencilIcon,
  PlusIcon,
  CheckIcon,
  ChevronDownIcon,
} from "@heroicons/react/solid";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-hot-toast";
import { useState, useEffect } from "react";
import TextEditor from "../components/RichTextEditor";
import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { useOutletContext } from "react-router-dom";
import { useFileUpload, useUserId } from "@nhost/react";
import {
  getPublicUrl,
  NhostProvider,
  NhostClient,
  useNhostClient,
} from "@nhost/react";

const privacyTypes = [{ name: "Private" }, { name: "Public" }];
const readingStatus = [
  { name: "Plan" },
  { name: "In progress" },
  { name: "Completed" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const GET_MOTIVATORS_QUERY = gql`
  query MyQuery($id: uuid!) {
    motivators_by_pk(id: $id) {
      updated_at
      title
      privacy
      id
      description
      short_description
      deleted
      created_at
      soul_id
      file {
        id
      }
    }
  }
`;

const GET_BOOKS_QUERY = gql`
  query MyQuery($id: uuid!) {
    books_by_pk(id: $id) {
      updated_at
      title
      privacy
      author
      status
      id
      description
      deleted
      created_at
      soul_id
      file {
        id
      }
    }
  }
`;

const UPDATE_MOTIVATOR = gql`
  mutation MyMutation(
    $title: String!
    $short_description: String!
    $privacy: String!
    $file_id: uuid!
    $description: String!
    $id: uuid!
  ) {
    update_motivators_by_pk(
      pk_columns: { id: $id }
      _set: {
        title: $title
        short_description: $short_description
        privacy: $privacy
        file_id: $file_id
        description: $description
      }
    ) {
      title
      short_description
      privacy
      file_id
      id
      description
    }
  }
`;

const UPDATE_BOOK = gql`
  mutation MyMutation(
    $title: String!
    $status: String!
    $author: String!
    $privacy: String!
    $file_id: uuid!
    $description: String!
    $id: uuid!
  ) {
    update_books_by_pk(
      pk_columns: { id: $id }
      _set: {
        title: $title
        status: $status
        author: $author
        privacy: $privacy
        file_id: $file_id
        description: $description
      }
    ) {
      title
      status
      author
      privacy
      file_id
      id
      description
    }
  }
`;

const INSERT_MOTIVATOR = gql`
  mutation MyMutation(
    $title: String!
    $soul_id: uuid!
    $privacy: String!
    $description: String!
    $short_description: String!
    $file_id: uuid!
  ) {
    insert_motivators_one(
      object: {
        title: $title
        soul_id: $soul_id
        file_id: $file_id
        privacy: $privacy
        short_description: $short_description
        description: $description
      }
    ) {
      id
    }
  }
`;

const INSERT_BOOK = gql`
  mutation MyMutation(
    $title: String!
    $soul_id: uuid!
    $privacy: String!
    $description: String!
    $status: String!
    $author: String!
    $file_id: uuid!
  ) {
    insert_books_one(
      object: {
        title: $title
        soul_id: $soul_id
        file_id: $file_id
        privacy: $privacy
        description: $description
        status: $status
        author: $author
      }
    ) {
      id
    }
  }
`;

export default function CardDetailes() {
  const soulId = useUserId();
  const { user } = useOutletContext();
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [shortDescription, setShortDescription] = useState(null);
  const [status, setStatus] = useState(null);
  const [author, setAuthor] = useState(null);
  const [titleText, setTitleText] = useState("");
  const [privacy, setPrivacy] = useState("Private");
  const [typeShort, setTypeShort] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const getObjectId = useParams();
  const objectId = getObjectId.id;
  const nhost = new NhostClient({
    backendUrl: "https://upxymbcutmxglgmlifxj.nhost.run",
  });
  const files = [
    {
      title: "IMG_4985.HEIC",
      size: "3.9 MB",
      source:
        "https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80",
    },
  ];

  /// Start Get data quey

  let query;
  if (location.pathname.includes("motivators")) {
    query = GET_MOTIVATORS_QUERY;
  } else if (location.pathname.includes("books")) {
    query = GET_BOOKS_QUERY;
  }
  const { loading, error, data } = useQuery(query, {
    variables: { id: objectId },
    skip: !objectId,
  });

  useEffect(() => {
    if (data?.motivators_by_pk || location.pathname.includes("motivators")) {
      setTitle(data?.motivators_by_pk.title);
      setDescription(data?.motivators_by_pk.description);
      setPrivacy(data?.motivators_by_pk.privacy);
      setShortDescription(data?.motivators_by_pk.short_description);
      setFile(data?.motivators_by_pk.file.id);
      setTitleText("Full name");
      setTypeShort("Motivator");
    } else if (data?.books_by_pk || location.pathname.includes("books")) {
      setTitle(data?.books_by_pk.title);
      setDescription(data?.books_by_pk.description);
      setPrivacy(data?.books_by_pk.privacy);
      setStatus(data?.books_by_pk.status);
      setAuthor(data?.books_by_pk.author);
      setFile(data?.books_by_pk.file.id);
      setTypeShort("Book");
      setTitleText("Book title");
    }
  }, [data, user]);

  //// End get data

  //// Start insert object

  const {
    add,
    upload,
    cancel,
    isUploaded,
    isUploading,
    isError,
    progress,
    id,
    bucketId,
    name,
  } = useFileUpload();

  const [insertMotivator] = useMutation(
    location.pathname.includes("motivators") ? INSERT_MOTIVATOR : INSERT_BOOK
  );
  const [updatetQuery] = useMutation(
    location.pathname.includes("motivators") ? UPDATE_MOTIVATOR : UPDATE_BOOK
  );

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setPreviewUrl(URL.createObjectURL(selectedFile));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      if (file) {
        const { id } = await upload({ file });
        // Call the mutation to insert the motivator object
        const { data } = await insertMotivator({
          variables: {
            title: title,
            status: status,
            author: author,
            soul_id: soulId,
            privacy: privacy,
            description: description,
            short_description: shortDescription,
            file_id: id, // Use the ID of the uploaded file here
          },
        });

        // console.log("Inserted motivator ID:", data.insert_motivators_one.id);
        toast.success("Created successfully", { id: "addObject" });
        navigate(-1);
      } else {
        // No file selected
      }
    } catch (error) {
      console.log(error);
      toast.error("Unable to create object", { id: "addObject" });
    }
  };

  const updateFormSubmit = async (e) => {
    e.preventDefault();
    try {
      if (file) {
        const { id } = await upload({ file });
        // Call the mutation to insert the motivator object
        const { data } = await updatetQuery({
          variables: {
            id: objectId,
            title: title,
            status: status,
            author: author,
            soul_id: soulId,
            privacy: privacy,
            description: description,
            short_description: shortDescription,
            file_id: id ? id : file, // Use the ID of the uploaded file here
          },
        });

        toast.success("Updated successfully", { id: "updateObject" });
        navigate(-1);
      } else {
        // No file selected
      }
    } catch (error) {
      console.log(error);
      toast.error("Unable to update object", { id: "updateObject" });
    }
  };

  //// End insert object

  const descriptionToDisplay =
    data?.motivators_by_pk?.description ||
    data?.books_by_pk?.description ||
    description ||
    "";

  return (
    <div className="bg-white px-6 py-4 lg:px-8 border border-gray-100 rounded-md  my-4">
      <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
        <form
          onSubmit={
            location.pathname.includes("create")
              ? handleFormSubmit
              : updateFormSubmit
          }
          className=""
        >
          <div className="grid grid-cols-1 gap-x-2 gap-y-6 sm:grid-cols-3 sm:gap-x-4 lg:grid-cols-5 xl:gap-x-6 pt-4">
            {location.pathname.includes("motivators") ? (
              <div className=" col-span-2 ">
                <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 ">
                  {file ? (
                    <img
                      src={nhost.storage.getPublicUrl({
                        fileId: `${file}`,
                      })}
                      alt=""
                      className="pointer-events-none object-cover group-hover:opacity-75"
                    />
                  ) : (
                    <div className="flex items-center justify-center cursor-pointer hover:bg-indigo-400 text-indigo-600 font-semibold">
                      Upload photo (png, jif, jpeg)
                    </div>
                  )}
                  {previewUrl && (
                    <img
                      src={previewUrl}
                      alt="Preview"
                      className="object-cover"
                    />
                  )}
                  <div className="absolute inset-0 focus:outline-none">
                    <label
                      htmlFor="file-upload"
                      className="absolute w-full h-full  cursor-pointer  rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-blue-500"
                    >
                      <input
                        accept="image/png, image/gif, image/jpeg"
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        className="sr-only"
                        onChange={handleFileChange}
                      />
                    </label>
                  </div>
                </div>
              </div>
            ) : (
              <div className=" col-span-2 ">
                <div className="group aspect-h-10 aspect-w-7 block w-full overflow-hidden rounded-lg bg-gray-100 ">
                  {file ? (
                    <img
                      src={nhost.storage.getPublicUrl({
                        fileId: `${file}`,
                      })}
                      alt=""
                      className="pointer-events-none object-cover group-hover:opacity-75"
                    />
                  ) : (
                    <div className="flex items-center justify-center cursor-pointer hover:bg-indigo-400 text-indigo-600 font-semibold">
                      Upload book cover (png, jif, jpeg)
                    </div>
                  )}
                  {previewUrl && <img src={previewUrl} alt="Preview" />}
                  <div className="absolute inset-0 focus:outline-none">
                    <label
                      htmlFor="file-upload"
                      className="absolute w-full h-full  cursor-pointer  rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-blue-500"
                    >
                      <input
                        accept="image/png, image/gif, image/jpeg"
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        className="sr-only"
                        onChange={handleFileChange}
                      />
                    </label>
                  </div>
                </div>
              </div>
            )}
            <div className=" col-span-3 ">
              <div className="grid grid-cols-6  gap-2">
                <div className="col-span-full">
                  <label
                    htmlFor="title"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {titleText}
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      value={title !== null ? title : ""}
                      onChange={(e) => setTitle(e.target.value)}
                      // placeholder="Name your win"
                      required
                      name="title"
                      id="title"
                      autoComplete="title"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                {location.pathname.includes("motivators") && (
                  <div className="col-span-full">
                    <label
                      htmlFor="title"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Title
                    </label>
                    <div className="mt-2">
                      <textarea
                        type="text"
                        rows={3}
                        value={
                          shortDescription !== null ? shortDescription : ""
                        }
                        onChange={(e) => setShortDescription(e.target.value)}
                        // placeholder="Name your win"
                        required
                        name="shortDescription"
                        id="shortDescription"
                        autoComplete="shortDescription"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                )}
                {location.pathname.includes("books") && (
                  <div className="col-span-full">
                    <div className="col-span-full">
                      <label
                        htmlFor="title"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Author
                      </label>
                      <div className="mt-2">
                        <textarea
                          type="text"
                          value={author !== null ? author : ""}
                          onChange={(e) => setAuthor(e.target.value)}
                          // placeholder="Name your win"
                          required
                          name="author"
                          id="author"
                          autoComplete="author"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <Listbox
                      value={status}
                      onChange={setStatus}
                      className="w-60 pt-2 z-50"
                    >
                      <div className="z-50 relative">
                        <label
                          htmlFor="title"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Status
                        </label>
                        <Listbox.Button className="mt-2 relative w-full cursor-default rounded-md bg-white py-2 pl-3 pr-10 text-left border focus:outline-none focus:ring-indigo-600 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                          <span className="block truncate">
                            {/* {data ? privacy : privacyTypes.name} */}
                            {status === "Plan"
                              ? "Plan"
                              : status === "Completed"
                              ? "Completed"
                              : "In progress"}
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>
                        <Transition
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {readingStatus.map(
                              (readingStatus, readingStatusIdx) => (
                                <Listbox.Option
                                  key={readingStatusIdx}
                                  className={({ active }) =>
                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                      active
                                        ? "bg-amber-100 text-amber-900"
                                        : "text-gray-900"
                                    }`
                                  }
                                  value={readingStatus.name}
                                >
                                  {({ selected }) => (
                                    <>
                                      <span
                                        className={`block truncate ${
                                          selected
                                            ? "font-medium"
                                            : "font-normal"
                                        }`}
                                      >
                                        {readingStatus.name}
                                      </span>
                                      {selected ? (
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              )
                            )}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </Listbox>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className=" block pb-6 w-full sm:text-sm col-span-6 pt-3">
            <label
              htmlFor="title"
              className="block text-sm font-medium leading-6 text-gray-900 pb-2"
            >
              Describe
            </label>
            <TextEditor
              description={descriptionToDisplay}
              setDescription={setDescription}
            ></TextEditor>
          </div>
          <Listbox value={privacy} onChange={setPrivacy} className="w-60">
            <div className="z-50 relative">
              <label
                htmlFor="title"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Privacy
              </label>
              <Listbox.Button className="mt-2 relative w-full cursor-default rounded-md bg-white py-2 pl-3 pr-10 text-left border focus:outline-none focus:ring-indigo-600 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                <span className="block truncate">
                  {/* {data ? privacy : privacyTypes.name} */}
                  {privacy === "Public" ? "Public" : "Private"}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {privacyTypes.map((privacyTypes, privacyTypesIdx) => (
                    <Listbox.Option
                      key={privacyTypesIdx}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                          active
                            ? "bg-amber-100 text-amber-900"
                            : "text-gray-900"
                        }`
                      }
                      value={privacyTypes.name}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {privacyTypes.name}
                          </span>
                          {selected ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>

          {/* <div className="flex text-sm text-gray-600 justify-center">
            <label
              htmlFor="file-upload"
              className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-blue-500"
            >
              <span>Upload</span>
              <input
                accept="image/png, image/gif, image/jpeg"
                id="file-upload"
                name="file-upload"
                type="file"
                className="sr-only"
                onChange={handleFileChange}
              />
            </label>
          </div> */}
          <div className="flex flex-row space-x-2 pt-6">
            <button
              type="submit"
              className="flex items-center gap-x-1 bg-green-500 rounded-md border border-gray-100 px-3 py-2 text-sm font-normal text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
            >
              Save
            </button>
            <button
              type="button"
              onClick={() => {
                navigate(-1);
              }}
              className="flex items-center gap-x-1 bg-red-500 rounded-md border border-gray-100 px-3 py-2 text-sm font-normal text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
