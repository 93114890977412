import { ArrowLeftIcon, PencilIcon, PlusIcon } from "@heroicons/react/solid";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useState, useEffect } from "react";
import { TypographyStylesProvider } from "@mantine/core";
import { useOutletContext } from "react-router-dom";
import {
  getPublicUrl,
  NhostProvider,
  NhostClient,
  useNhostClient,
} from "@nhost/react";
import mixpanel from "mixpanel-browser";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const GET_USER_QUERY = gql`
  query GetUser($id: uuid!) {
    user(id: $id) {
      id
      email
      displayName
      metadata
      avatarUrl
    }
  }
`;

const GET_MOTIVATORS_QUERY = gql`
  query MyQuery($id: uuid!) {
    motivators_by_pk(id: $id) {
      updated_at
      title
      privacy
      id
      description
      short_description
      deleted
      created_at
      soul_id
      file {
        id
      }
    }
  }
`;

const GET_BOOKS_QUERY = gql`
  query MyQuery($id: uuid!) {
    books_by_pk(id: $id) {
      updated_at
      title
      privacy
      id
      author
      description
      status
      deleted
      created_at
      soul_id
      file {
        id
      }
    }
  }
`;

const DELETE_MOTIVATOR_MUTATION = gql`
  mutation MyMutation($id: uuid!) {
    update_motivators_by_pk(pk_columns: { id: $id }, _set: { deleted: true }) {
      id
      soul_id
    }
  }
`;

const DELETE_BOOKS_MUTATION = gql`
  mutation MyMutation($id: uuid!) {
    update_books_by_pk(pk_columns: { id: $id }, _set: { deleted: true }) {
      id
      soul_id
    }
  }
`;

export default function SimpleCardDetailes({ items }) {
  const { user } = useOutletContext();
  const [deleted, setDeleted] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [type, setType] = useState("");
  const [typeShort, setTypeShort] = useState("");
  const [privacy, setPrivacy] = useState("");
  const [creatorId, setCreatorId] = useState("");
  const [fileId, setFileId] = useState("");
  const [author, setAuthor] = useState("");
  const [status, setStatus] = useState("");
  const location = useLocation();
  const parts = location.pathname.split("/");
  const newLocation = parts.slice(0, 3).join("/");
  const newLocationTwo = parts.slice(0, 4).join("/");
  const navigate = useNavigate();
  const getObjectId = useParams();
  const soulId = getObjectId.soulId;
  const id = getObjectId.id;
  const nhost = new NhostClient({
    backendUrl: "https://upxymbcutmxglgmlifxj.nhost.run",
  });

  let query;
  if (location.pathname.includes("motivators")) {
    query = GET_MOTIVATORS_QUERY;
  } else if (location.pathname.includes("books")) {
    query = GET_BOOKS_QUERY;
  }
  const { loading, error, data, refetch } = useQuery(query, {
    variables: { id: id },
    skip: !id,
  });

  useEffect(() => {
    if (data?.motivators_by_pk) {
      setTitle(data?.motivators_by_pk.title);
      setDescription(data?.motivators_by_pk.description);
      setType(data?.motivators_by_pk.__typename);
      setPrivacy(data?.motivators_by_pk.privacy);
      setCreatorId(data?.motivators_by_pk.soul_id);
      setShortDescription(data?.motivators_by_pk.short_description);
      setFileId(data?.motivators_by_pk.file.id);
      setTypeShort("Motivator");
    } else if (data?.books_by_pk) {
      setTitle(data?.books_by_pk.title);
      setDescription(data?.books_by_pk.description);
      setType(data?.books_by_pk.__typename);
      setPrivacy(data?.books_by_pk.privacy);
      setCreatorId(data?.books_by_pk.soul_id);
      setAuthor(data?.books_by_pk.author);
      setStatus(data?.books_by_pk.status);
      setFileId(data?.books_by_pk.file.id);
      setTypeShort("Book");
    }
  }, [data, user]);

  useEffect(() => {
    refetch();
  }, []);

  /// Delete the object START

  const [removeObject, { loading: deletingObject }] = useMutation(
    data?.motivators_by_pk
      ? DELETE_MOTIVATOR_MUTATION
      : data?.motivators_by_pk
      ? DELETE_BOOKS_MUTATION
      : DELETE_BOOKS_MUTATION
  );

  const deleteObject = async (e) => {
    e.preventDefault();

    try {
      await removeObject({
        variables: {
          id: id,
          soul_id: soulId,
        },
        refetchQueries: [
          { query: query, variables: { id: id } },
          // Add any other queries that need to be refetched
        ],
      });
      toast.success("Deleted successfully", { id: "deleteObject" });
      navigate(newLocation);
    } catch (error) {
      console.log(error);
      toast.error("Unable to delete the object", { id: "deleteObject" });
    }
  };

  /// Delete the object END

  // Mixpanel start

  const {
    loading: loading2,
    error: error2,
    data: data2,
  } = useQuery(GET_USER_QUERY, {
    variables: { id: creatorId },
    skip: !creatorId,
  });

  useEffect(() => {
    // Check if both user data and data2 are loaded
    if (user && data2 && data) {
      const soulFullName = `${user?.metadata?.firstName} ${user?.metadata?.lastName}`;
      const soulToSeeFullName = `${data2?.user?.metadata?.firstName} ${data2?.user?.metadata?.lastName}`;

      mixpanel.track("Card Visit", {
        page: typeShort,
        user_id: user?.id,
        user_full_name: soulFullName,
        creator_id: creatorId,
        creator_full_name: soulToSeeFullName,
        card_id: id,
        card_title: title,
      });
    }
  }, [user, data2, data]);

  // Mixpanel end

  return (
    <div className="bg-white px-6 py-12 lg:px-8 border border-gray-100 rounded-md  my-4 w-full max-w-4xl mx-auto">
      <div className="mx-auto max-w-2xl text-base leading-7 text-gray-700">
        <div className="flex flex-wrap items-center gap-2  sm:flex-nowrap pb-4 justify-between">
          <Link
            to={{
              pathname: `${newLocation}`,
            }}
            className="flex items-center gap-x-1 rounded-md border border-gray-100 px-3 py-2 text-sm font-normal text-gray-500 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
          >
            <ArrowLeftIcon className="-ml-1.5 h-5 w-5" aria-hidden="true" />
            Back
          </Link>
          {location.pathname.includes("souls") ? (
            ""
          ) : (
            <div className="flex space-x-2">
              <Link
                to={{
                  pathname: `${newLocation}/edit/${id}`,
                }}
                className="ml-auto flex items-center gap-x-1 rounded-md border border-gray-100 px-3 py-2 text-sm font-normal text-gray-500 shadow-sm hover:bg-indigo-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                <PencilIcon className="-ml-1.5 h-5 w-5" aria-hidden="true" />
                Edit
              </Link>
              <form onSubmit={deleteObject}>
                <button
                  type="submit"
                  className="ml-auto flex items-center gap-x-1 rounded-md border bg-red-400 border-gray-100 px-3 py-2 text-sm font-normal text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Delete
                </button>
              </form>
            </div>
          )}
        </div>
        <div className="grid grid-cols-1 gap-x-2 gap-y-6 sm:grid-cols-3 sm:gap-x-4 lg:grid-cols-5 xl:gap-x-6 pt-4">
          <div className=" col-span-2 ">
            {location.pathname.includes("motivators") ? (
              <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 ">
                <img
                  src={nhost.storage.getPublicUrl({
                    fileId: `${fileId}`,
                  })}
                  alt=""
                  className="pointer-events-none object-cover group-hover:opacity-75"
                />
                <button
                  type="button"
                  className="absolute inset-0 focus:outline-none"
                >
                  <span className="sr-only">View details for {title}</span>
                </button>
              </div>
            ) : (
              <div className="group aspect-h-10 aspect-w-7 block w-full overflow-hidden rounded-lg bg-gray-100 ">
                <img
                  src={nhost.storage.getPublicUrl({
                    fileId: `${fileId}`,
                  })}
                  alt=""
                  className="pointer-events-none object-cover group-hover:opacity-75"
                />
                <button
                  type="button"
                  className="absolute inset-0 focus:outline-none"
                >
                  <span className="sr-only">View details for {title}</span>
                </button>
              </div>
            )}
          </div>
          <div className="col-span-2">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl pb-2">
              {title ? title : ""}
            </h1>
            <p className="text-base font-semibold  text-indigo-600">
              {shortDescription}
            </p>
            {author && (
              <p className="mt-2 flex gap-x-2 text-sm leading-6 text-gray-500">
                <span className="font-semibold">Author:</span> {author}
              </p>
            )}
            {status && (
              <p className="mt-2 flex gap-x-2 text-sm leading-6 text-gray-500">
                <span className="font-semibold">Status:</span> {status}
              </p>
            )}
            <figcaption className="mt-2 flex gap-x-2 text-sm leading-6 text-gray-500">
              <span className="font-semibold">Privacy:</span> {privacy}
            </figcaption>
          </div>
        </div>
        <div className="max-w-2xl prose lg:prose-x pt-4">
          <TypographyStylesProvider>
            <div
              dangerouslySetInnerHTML={{ __html: description }}
              className="prose lg:prose-lg"
            />
          </TypographyStylesProvider>
        </div>
      </div>
    </div>
  );
}
