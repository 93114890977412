import {
  ArrowLeftIcon,
  PencilIcon,
  PlusIcon,
  CheckIcon,
  ChevronDownIcon,
} from "@heroicons/react/solid";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-hot-toast";
import { useState, useEffect } from "react";
import TextEditor from "../components/RichTextEditor";
import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { useOutletContext } from "react-router-dom";
import { useUserId } from "@nhost/react";

const GET_WIN_QUERY = gql`
  query MyQuery($id: uuid!) {
    wins_by_pk(id: $id) {
      color
      created_at
      date
      deleted
      description
      id
      privacy
      soul_id
      title
      updated_at
    }
  }
`;

const GET_PRINCIPLES_QUERY = gql`
  query MyQuery($id: uuid!) {
    principles_by_pk(id: $id) {
      color
      created_at
      date
      deleted
      description
      id
      privacy
      soul_id
      title
      updated_at
    }
  }
`;

const GET_INSIGHT_QUERY = gql`
  query MyQuery($id: uuid!) {
    insights_by_pk(id: $id) {
      color
      created_at
      date
      deleted
      description
      id
      privacy
      soul_id
      title
      updated_at
    }
  }
`;

const GET_ACTIVITY_QUERY = gql`
  query MyQuery($id: uuid!) {
    activities_by_pk(id: $id) {
      color
      created_at
      date
      scope
      deleted
      description
      id
      privacy
      soul_id
      title
      updated_at
    }
  }
`;

const UPDATE_WIN_MUTATION = gql`
  mutation MyMutation(
    $id: uuid!
    $title: String!
    $description: String!
    $date: date
    $privacy: String!
  ) {
    update_wins_by_pk(
      pk_columns: { id: $id }
      _set: {
        title: $title
        description: $description
        date: $date
        privacy: $privacy
      }
    ) {
      id
      title
      date
      privacy
    }
  }
`;

const UPDATE_INSIGHT_MUTATION = gql`
  mutation MyMutation(
    $id: uuid!
    $title: String!
    $description: String!
    $date: date
    $privacy: String!
  ) {
    update_insights_by_pk(
      pk_columns: { id: $id }
      _set: {
        title: $title
        description: $description
        date: $date
        privacy: $privacy
      }
    ) {
      id
      title
      date
      privacy
    }
  }
`;

const UPDATE_ACTIVITY_MUTATION = gql`
  mutation MyMutation(
    $id: uuid!
    $title: String!
    $description: String!
    $date: date
    $privacy: String!
    $scope: String!
  ) {
    update_activities_by_pk(
      pk_columns: { id: $id }
      _set: {
        title: $title
        description: $description
        date: $date
        privacy: $privacy
        scope: $scope
      }
    ) {
      id
      title
      date
      privacy
      scope
    }
  }
`;

const UPDATE_PRINCIPLE_MUTATION = gql`
  mutation MyMutation(
    $id: uuid!
    $title: String!
    $description: String!
    $date: date
    $privacy: String!
  ) {
    update_principles_by_pk(
      pk_columns: { id: $id }
      _set: {
        title: $title
        description: $description
        date: $date
        privacy: $privacy
      }
    ) {
      id
      title
      date
      privacy
    }
  }
`;

const UPDATE_USER_MUTATION = gql`
  mutation ($id: uuid!, $metadata: jsonb) {
    updateUser(pk_columns: { id: $id }, _set: { metadata: $metadata }) {
      id
      metadata
    }
  }
`;

const INSERT_WIN = gql`
  mutation MyMutation(
    $title: String!
    $soul_id: uuid!
    $privacy: String!
    $description: String!
    $date: date
  ) {
    insert_wins_one(
      object: {
        title: $title
        soul_id: $soul_id
        privacy: $privacy
        description: $description
        date: $date
      }
    ) {
      id
    }
  }
`;
const INSERT_INSIGHT = gql`
  mutation MyMutation(
    $title: String!
    $soul_id: uuid!
    $privacy: String!
    $description: String!
    $date: date
  ) {
    insert_insights_one(
      object: {
        title: $title
        soul_id: $soul_id
        privacy: $privacy
        description: $description
        date: $date
      }
    ) {
      id
    }
  }
`;

const INSERT_PRINCIPLE = gql`
  mutation MyMutation(
    $title: String!
    $soul_id: uuid!
    $privacy: String!
    $description: String!
    $date: date
  ) {
    insert_principles_one(
      object: {
        title: $title
        soul_id: $soul_id
        privacy: $privacy
        description: $description
        date: $date
      }
    ) {
      id
    }
  }
`;

const INSERT_ACTIVITY = gql`
  mutation MyMutation(
    $title: String!
    $soul_id: uuid!
    $privacy: String!
    $description: String!
    $date: date
    $scope: String!
  ) {
    insert_activities_one(
      object: {
        title: $title
        soul_id: $soul_id
        privacy: $privacy
        description: $description
        date: $date
        scope: $scope
      }
    ) {
      id
    }
  }
`;

const GET_USER_QUERY = gql`
  query GetUser($id: uuid!) {
    user(id: $id) {
      id
      email
      displayName
      metadata
      avatarUrl
    }
  }
`;

const privacyTypes = [{ name: "Private" }, { name: "Public" }];
const scopeList = [
  { name: "Self-development" },
  { name: "Self-expression" },
  { name: "Mental health" },
  { name: "Physical health" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SimpleCardDetailes() {
  const soulId = useUserId();
  const { user } = useOutletContext();
  const [firstName, setFirstName] = useState(user?.metadata?.firstName ?? "");
  const [lastName, setLastName] = useState(user?.metadata?.lastName ?? "");
  const [about, setAbout] = useState(user?.metadata?.about ?? "");
  const [backgroundImage, setBackgroundImage] = useState(
    user?.metadata?.backgroundImage ?? ""
  );
  const [title, setTitle] = useState(null);
  const [scope, setScope] = useState("Self-development");
  const [description, setDescription] = useState(null);
  const [privacy, setPrivacy] = useState("Private");
  const [date, setDate] = useState("");
  const [typeShort, setTypeShort] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const getObjectId = useParams();
  const id = getObjectId.id;

  let query;
  if (location.pathname.includes("wins")) {
    query = GET_WIN_QUERY;
  } else if (location.pathname.includes("principles")) {
    query = GET_PRINCIPLES_QUERY;
  } else if (location.pathname.includes("insights")) {
    query = GET_INSIGHT_QUERY;
  } else if (location.pathname.includes("activities")) {
    query = GET_ACTIVITY_QUERY;
  } else if (location.pathname.includes("about")) {
    query = GET_USER_QUERY;

    // } else if (location.pathname.includes("activities")) {
    //   query = GET_ACTIVITIES_QUERY;
    // } else if (location.pathname.includes("insights")) {
    //   query = GET_ACTIVITIES_QUERY;
  }

  const { loading, error, data } = useQuery(query, {
    variables: { id: id ? id : soulId },
    skip: !{ id: id },
  });

  useEffect(() => {
    if (data?.wins_by_pk) {
      setTitle(data?.wins_by_pk.title);
      setDescription(data?.wins_by_pk.description);
      setPrivacy(data?.wins_by_pk.privacy);
      setDate(data?.wins_by_pk.date);
    } else if (data?.principles_by_pk) {
      setTitle(data?.principles_by_pk.title);
      setDescription(data?.principles_by_pk.description);
      setPrivacy(data?.principles_by_pk.privacy);
      setDate(data?.principles_by_pk.date);
    } else if (data?.insights_by_pk) {
      setTitle(data?.insights_by_pk.title);
      setDescription(data?.insights_by_pk.description);
      setPrivacy(data?.insights_by_pk.privacy);
      setDate(data?.insights_by_pk.date);
    } else if (data?.activities_by_pk) {
      setTitle(data?.activities_by_pk.title);
      setDescription(data?.activities_by_pk.description);
      setPrivacy(data?.activities_by_pk.privacy);
      setDate(data?.activities_by_pk.date);
    } else if (location.pathname.includes("about")) {
      // setTitle(data?.principles_by_pk.title);
      setDescription(user?.metadata?.description);
      // setPrivacy(data?.principles_by_pk.privacy);
      // setColor(data?.principles_by_pk.color);
      // setDate(data?.principles_by_pk.date);
      // setDateTitle("Effective date");
      setTypeShort("About");
    }
  }, [data, user]);

  const [mutateObject, { loading: updatingObject }] = useMutation(
    data?.wins_by_pk
      ? UPDATE_WIN_MUTATION
      : data?.principles_by_pk
      ? UPDATE_PRINCIPLE_MUTATION
      : data?.insights_by_pk
      ? UPDATE_INSIGHT_MUTATION
      : data?.activities_by_pk
      ? UPDATE_ACTIVITY_MUTATION
      : UPDATE_ACTIVITY_MUTATION
  );

  const updateObject = async (e) => {
    e.preventDefault();

    try {
      await mutateObject({
        variables: {
          id: id,
          title: title,
          scope: scope,
          description: description,
          date: date,
          privacy: privacy,
        },
        refetchQueries: [
          { query: query, variables: { id: id } },
          // Add any other queries that need to be refetched
        ],
      });
      toast.success("Updated successfully", { id: "updateObject" });
      navigate(-1);
    } catch (error) {
      console.log(error);
      toast.error("Unable to update profile", { id: "updateObject" });
    }
  };

  const [insertObject, { loading: insertingObject }] = useMutation(
    location.pathname.includes("wins/create")
      ? INSERT_WIN
      : location.pathname.includes("principles/create")
      ? INSERT_PRINCIPLE
      : location.pathname.includes("insights/create")
      ? INSERT_INSIGHT
      : location.pathname.includes("activities/create")
      ? INSERT_ACTIVITY
      : INSERT_ACTIVITY
  );

  const addObject = async (e) => {
    e.preventDefault();

    try {
      await insertObject({
        variables: {
          soul_id: soulId,
          title: title,
          description: description,
          scope: scope,
          date: date,
          privacy: privacy,
        },
      });
      toast.success("Created successfully", { id: "addObject" });
      navigate(-1);
    } catch (error) {
      console.log(error);
      toast.error("Unable to create object", { id: "addObject" });
    }
  };

  const [mutateUser, { loading: updatingProfile }] =
    useMutation(UPDATE_USER_MUTATION);

  const updateUserProfile = async (e) => {
    e.preventDefault();

    try {
      await mutateUser({
        variables: {
          id: user.id,
          metadata: {
            lastName,
            firstName,
            about,
            description,
            backgroundImage,
          },
        },
      });

      toast.success("Updated successfully", { id: "updateProfile" });
      navigate(-1);
    } catch (error) {
      toast.error("Unable to update profile", { id: "updateProfile" });
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    // Handle the error case
    return <p>Error: {error.message}</p>;
  }

  if (!data) {
    // Handle the case when there is no data
    return <p>No data available</p>;
  }

  const descriptionToDisplay =
    data?.wins_by_pk?.description ||
    data?.principles_by_pk?.description ||
    data?.insights_by_pk?.description ||
    data?.activities_by_pk?.description ||
    data?.user?.metadata?.description ||
    "";

  return (
    <div className="bg-white px-6 py-6 lg:px-8 border border-gray-100 rounded-md  my-4">
      <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
        <form
          onSubmit={
            location.pathname.includes("create")
              ? addObject
              : location.pathname.includes("about")
              ? updateUserProfile
              : updateObject
          }
        >
          {location.pathname.includes("about") ? (
            ""
          ) : (
            <div className="grid grid-cols-6  gap-4">
              <div className="col-span-full">
                <label
                  htmlFor="title"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Title
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    value={title !== null ? title : ""}
                    onChange={(e) => setTitle(e.target.value)}
                    // placeholder="Name your win"
                    required
                    name="title"
                    id="title"
                    autoComplete="title"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <Listbox
                value={privacy}
                onChange={setPrivacy}
                className="sm:col-span-2 col-span-full"
              >
                <div className="z-50 relative">
                  <label
                    htmlFor="title"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Privacy
                  </label>
                  <Listbox.Button className="mt-2 relative w-full cursor-default rounded-md bg-white py-2 pl-3 pr-10 text-left border focus:outline-none focus:ring-indigo-600 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                    <span className="block truncate">
                      {/* {data ? privacy : privacyTypes.name} */}
                      {privacy === "Public" ? "Public" : "Private"}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {privacyTypes.map((privacyTypes, privacyTypesIdx) => (
                        <Listbox.Option
                          key={privacyTypesIdx}
                          className={({ active }) =>
                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                              active
                                ? "bg-amber-100 text-amber-900"
                                : "text-gray-900"
                            }`
                          }
                          value={privacyTypes.name}
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selected ? "font-medium" : "font-normal"
                                }`}
                              >
                                {privacyTypes.name}
                              </span>
                              {selected ? (
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
              {location.pathname.includes("activities") ? (
                <Listbox
                  value={scope}
                  onChange={setScope}
                  className="sm:col-span-2 col-span-full"
                >
                  <div className="z-50 relative">
                    <label
                      htmlFor="title"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Type
                    </label>
                    <Listbox.Button className="mt-2 relative w-full cursor-default rounded-md bg-white py-2 pl-3 pr-10 text-left border focus:outline-none focus:ring-indigo-600 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                      <span className="block truncate">
                        {/* {data ? privacy : privacyTypes.name} */}
                        {scope === "Self-development"
                          ? "Self-development"
                          : scope === "Self-expression"
                          ? "Self-expression"
                          : scope === "Mental health"
                          ? "Mental health"
                          : "Physical health"}
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {scopeList.map((scopeList, scopeListIdx) => (
                          <Listbox.Option
                            key={scopeListIdx}
                            className={({ active }) =>
                              `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                active
                                  ? "bg-amber-100 text-amber-900"
                                  : "text-gray-900"
                              }`
                            }
                            value={scopeList.name}
                          >
                            {({ selected }) => (
                              <>
                                <span
                                  className={`block truncate ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}
                                >
                                  {scopeList.name}
                                </span>
                                {selected ? (
                                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>
              ) : (
                ""
              )}
              <div className="sm:col-span-2 col-span-full">
                <label
                  htmlFor="title"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Effective date
                </label>
                <div className="mt-2">
                  <input
                    type="date"
                    value={date !== null ? date : ""}
                    onChange={(e) => setDate(e.target.value)}
                    required
                    name="date"
                    id="date"
                    autoComplete="date"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
          )}

          <div className=" block pb-6 w-full sm:text-sm col-span-6 pt-3">
            <label
              htmlFor="title"
              className="block text-sm font-medium leading-6 text-gray-900 pb-2"
            >
              Describe
            </label>
            <TextEditor
              description={descriptionToDisplay}
              setDescription={setDescription}
            ></TextEditor>
          </div>
          <div className="flex flex-row space-x-2">
            <button
              type="submit"
              className="flex items-center gap-x-1 bg-green-500 rounded-md border border-gray-100 px-3 py-2 text-sm font-normal text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
            >
              Save
            </button>
            <button
              type="button"
              onClick={() => {
                navigate(-1);
              }}
              className="flex items-center gap-x-1 bg-red-500 rounded-md border border-gray-100 px-3 py-2 text-sm font-normal text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
