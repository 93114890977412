import { useState } from "react";
import { PlusIcon } from "@heroicons/react/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SecondaryNavigation({
  title,
  action,
  secondaryNavigation,
  setSelectedType, // Add setSelectedType as a prop
}) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <>
      <header className="pb-4 sm:pb-6 ">
        <div className="mx-auto flex max-w-7xl flex-wrap items-center gap-6 sm:flex-nowrap ">
          <h1 className="text-base font-semibold leading-7 text-gray-900">
            {title}
          </h1>
          <div className="order-last flex w-full gap-x-8 text-sm  leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">
            {secondaryNavigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className={classNames(
                  item.selected
                    ? "text-indigo-600 font-semibold"
                    : "text-gray-700",
                  "cursor-pointer"
                )}
                onClick={() => setSelectedType(item.name)}
              >
                {item.name}
              </a>
            ))}
          </div>
          <a
            href="#"
            className="ml-auto flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <PlusIcon className="-ml-1.5 h-5 w-5" aria-hidden="true" />
            {action}
          </a>
        </div>
      </header>
    </>
  );
}
