import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Tabs({ tabs }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(
    () => tabs.find((tab) => tab.selected).name
  );

  const handleTabClick = (tabName, href) => {
    setSelectedTab(tabName);
    navigate(href);
  };

  return (
    <div className=" w-full ">
      <div className="md:hidden w-full block px-2">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block rounded-md w-full border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          value={selectedTab}
          onChange={(e) => {
            const selectedTab = e.target.value;
            const tab = tabs.find((tab) => tab.name === selectedTab);
            handleTabClick(tab.name, tab.href);
          }}
        >
          {tabs.map((tab) => (
            <option key={tab.name} value={tab.name}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden md:block md:w-full">
        <nav
          className="isolate flex divide-x divide-gray-200 rounded-lg shadow"
          aria-label="Tabs"
        >
          {tabs.map((tab, tabIdx) => (
            <Link
              key={tab.name}
              to={tab.href}
              onClick={() => handleTabClick(tab.name, tab.href)}
              className={classNames(
                tab.selected
                  ? "text-gray-900"
                  : "text-gray-500 hover:text-gray-700",
                tabIdx === 0 ? "rounded-l-lg" : "",
                tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                "group relative min-w-0 flex-1 overflow-hidden bg-white py-3 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
              )}
              aria-current={tab.selected ? "page" : undefined}
            >
              <span>{tab.name}</span>
              <span
                aria-hidden="true"
                className={classNames(
                  tab.selected ? "bg-indigo-500" : "bg-transparent",
                  "absolute inset-x-0 bottom-0 h-0.5"
                )}
              />
            </Link>
          ))}
        </nav>
      </div>
    </div>
  );
}
