import { gql, useQuery } from "@apollo/client";
import Card from "../components/Card";
import React, { useEffect } from "react";
import { useUserId } from "@nhost/react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

const GET_BOOKS_QUERY = gql`
  query MyQuery($id: uuid!) {
    books(
      order_by: { updated_at: desc }
      where: { soul_id: { _eq: $id }, deleted: { _eq: false } }
    ) {
      updated_at
      title
      privacy
      id
      description
      deleted
      created_at
      soul_id
      status
      file {
        id
      }
    }
  }
`;

const icon = "/trophy.svg";

const Motivators = () => {
  const id = useUserId();
  const { loading, error, data, refetch } = useQuery(GET_BOOKS_QUERY, {
    variables: { id: id },
    skip: !{ id: id },
  });

  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <Helmet>
        <title>Books</title>
      </Helmet>
      <div className="">
        {/* <Card /> */}
        {data && (
          <Card items={data?.books} icon={icon} className="bg-yellow-400" />
        )}
      </div>
    </>
  );
};

export default Motivators;
