import ProfileHeader from "../components/ProfileHeader";
import React from "react";
import { gql, useQuery } from "@apollo/client";
import { Outlet } from "react-router-dom";
import SoulList from "../components/SoulList";
import { useUserId } from "@nhost/react";
import Helmet from "react-helmet";

const GET_USER_QUERY = gql`
  query GetUser($id: uuid!) {
    user(id: $id) {
      id
      email
      displayName
      metadata
      avatarUrl
    }
  }
`;

const Soul = () => {
  const id = useUserId();
  const { loading, error, data } = useQuery(GET_USER_QUERY, {
    variables: { id: id },
    skip: !{ id: id },
  });
  const user = data?.user;

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    // Handle the error case
    return <p>Error: {error.message}</p>;
  }

  if (!data) {
    // Handle the case when there is no data
    return <p>No data available</p>;
  }

  return (
    <>
      <Helmet>
        <title>
          Soul {user?.metadata?.firstName} {user?.metadata?.lastName}
        </title>
      </Helmet>
      <div className="mx-auto w-full max-w-7xl grow lg:flex px-2 sm:px-0 flex flex-row">
        <div className="flex-1 flex flex-col max-w-4xl w-full mx-auto">
          <ProfileHeader context={{ user }} />
          <Outlet context={{ user }} />
        </div>
        {/* <div className="hidden lg:block lg:w-80 lg:ml-6 space-y-4">
          <div className="overflow-hidden rounded-xl border border-gray-200 bg-white">
            <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 px-2 sm:px-3 lg:px-5 py-2">
              <div className="text-sm font-medium leading-6 text-gray-900">
                Your profile
              </div>
            </div>
            <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
              <div className="flex justify-between gap-x-4 py-3">
                <dt className="text-gray-500">Privacy status</dt>
                <dd className="flex items-start gap-x-2">
                  <div className="rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset text-green-700 bg-green-50 ring-green-600/20">
                    Public
                  </div>
                </dd>
              </div>
            </dl>
          </div>
          <div className="overflow-hidden rounded-xl border border-gray-200 bg-white">
            <SoulList />
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Soul;
