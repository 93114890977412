import React from "react";
import styles from "../styles/components/Layout.module.css";
import { useSignOut } from "@nhost/react";
import { useUserId } from "@nhost/react";
import { useState, useEffect, Fragment } from "react";
import { Outlet } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import Footer from "../components/footer";
import {
  ChevronDownIcon,
  DeviceMobileIcon,
  HomeIcon,
  LogoutIcon,
  UserIcon,
} from "@heroicons/react/outline";
import Avatar from "./Avatar";
import { Dialog, Menu, Transition } from "@headlessui/react";

const GET_USER_QUERY = gql`
  query GetUser($id: uuid!) {
    user(id: $id) {
      id
      email
      displayName
      phoneNumberVerified
      metadata
      disabled
      avatarUrl
    }
  }
`;

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Layout = () => {
  const id = useUserId();
  // highlight-start
  const { loading, error, data } = useQuery(GET_USER_QUERY, {
    variables: { id },
    skip: !id,
  });
  const user = data?.user;
  // highlight-end
  const { signOut } = useSignOut();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const navigation = [
    { name: "Profile", href: "/soul/about" },
    { name: "Souls", href: "/souls" },
  ];

  const [activePage, setActivePage] = useState("");

  useEffect(() => {
    const currentPath = window.location.pathname;
    if (currentPath.includes("/soul/")) {
      setActivePage("Profile");
    } else if (currentPath.includes("/souls")) {
      setActivePage("Souls");
    }
  }, []);

  return (
    <div className="flex flex-col min-h-screen sm:bg-gray-50 bg-white ">
      <header className="flex h-16 z-50 px-2 sm:px-0">
        <div className="mx-auto flex w-full max-w-6xl items-center justify-between ">
          <div className="flex flex-1 items-center gap-x-6">
            {/* <button
              type="button"
              className="-m-3 p-3 md:hidden"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <ChevronDownIcon
                className="h-5 w-5 text-gray-900"
                aria-hidden="true"
              />
            </button> */}
            <a href="/soul/about">
              <img className="h-10 w-auto" src="/slogo.png" alt="Spirit X" />
            </a>
          </div>
          <nav className="md:flex md:gap-x-11 md:text-sm font-semibold md:leading-6 text-yellow-600 space-x-4 ">
            {navigation.map((item, itemIdx) => (
              <a
                key={itemIdx}
                href={item.href}
                className={`${
                  activePage === item.name ? "active" : ""
                }text-gray-700 hover:underline`}
              >
                {item.name}
              </a>
            ))}
          </nav>
          <div className="flex flex-1 items-center justify-end gap-x-8">
            <div>
              <Menu>
                <Menu.Button>
                  <Avatar src={user?.avatarUrl} alt={user?.displayName} />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="px-4 py-3">
                      <p className="text-sm">Signed in as</p>
                      <p className="truncate text-sm font-medium text-gray-900">
                        {user?.displayName}
                      </p>
                      <p className="truncate text-sm font-medium text-gray-900">
                        {user?.email}
                      </p>
                    </div>
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="/soul/about"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm"
                            )}
                          >
                            My profile
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="/settings"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm"
                            )}
                          >
                            Settings
                          </a>
                        )}
                      </Menu.Item>
                    </div>
                    <div className="py-1">
                      <form method="POST" action="#">
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              type="submit"
                              onClick={signOut}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block w-full px-4 py-2 text-left text-sm"
                              )}
                            >
                              Sign out
                            </button>
                          )}
                        </Menu.Item>
                      </form>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 left-0 z-50 w-full overflow-y-auto bg-white px-4 pb-6 sm:max-w-sm sm:px-6 sm:ring-1 sm:ring-gray-900/10">
            <div className="-ml-0.5 flex h-16 items-center gap-x-6">
              <button
                type="button"
                className="-m-2.5 p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <DeviceMobileIcon className="h-6 w-6" aria-hidden="true" />
              </button>
              <div className="-ml-0.5">
                <a href="#" className="-m-1.5 block p-1.5">
                  <span className="sr-only">Your Company</span>
                  <img
                    className="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div className="mt-2 space-y-2">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  {item.name}
                </a>
              ))}
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>

      <main className="grow flex">
        <div className="mx-auto max-w-6xl flex flex-auto ">
          {/* highlight-start */}
          {error ? (
            <p>Something went wrong. Try to refresh the page.</p>
          ) : !loading ? (
            <Outlet className="flex" context={{ user }} />
          ) : null}
          {/* highlight-end */}
        </div>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default Layout;
