const reportWebVitals = (onPerfEntry) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import("web-vitals").then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS((metric) => {
        sendToGoogleAnalytics("CLS", metric);
        onPerfEntry(metric);
      });
      getFID((metric) => {
        sendToGoogleAnalytics("FID", metric);
        onPerfEntry(metric);
      });
      getFCP((metric) => {
        sendToGoogleAnalytics("FCP", metric);
        onPerfEntry(metric);
      });
      getLCP((metric) => {
        sendToGoogleAnalytics("LCP", metric);
        onPerfEntry(metric);
      });
      getTTFB((metric) => {
        sendToGoogleAnalytics("TTFB", metric);
        onPerfEntry(metric);
      });
    });
  }
};

const sendToGoogleAnalytics = (name, metric) => {
  // Replace 'UA-XXXXX-Y' with your Google Analytics tracking ID
  const trackingId = "G-R5X96M92V7";

  const payload = {
    v: "1",
    tid: trackingId,
    t: "event",
    ec: "Web Vitals",
    ea: name,
    el: metric.id,
    ev: metric.value,
  };

  const request = new XMLHttpRequest();
  request.open("POST", "https://www.google-analytics.com/collect");
  request.send(
    Object.keys(payload)
      .map((key) => `${key}=${encodeURIComponent(payload[key])}`)
      .join("&")
  );
};

export default reportWebVitals;
