import React from "react";
import {
  getPublicUrl,
  NhostProvider,
  NhostClient,
  useNhostClient,
} from "@nhost/react";

import { Link, useLocation, useNavigate } from "react-router-dom";

export default function Card({ items }) {
  const location = useLocation();
  const navigate = useNavigate();
  const nhost = new NhostClient({
    backendUrl: "https://upxymbcutmxglgmlifxj.nhost.run",
  });

  return (
    <ul
      role="list"
      className="grid grid-cols-1 gap-x-2 gap-y-6 sm:grid-cols-3 sm:gap-x-4 lg:grid-cols-4 xl:gap-x-2  pb-6  px-2 sm:px-0"
    >
      {items.map((item) => (
        <li key={item.id} className="relative">
          <Link
            to={{
              pathname: `${location.pathname}/${item.id}`,
            }}
          >
            <div className="group aspect-h-7 aspect-w-7 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
              <img
                src={item?.avatarUrl}
                alt=""
                className="pointer-events-none object-cover group-hover:opacity-75"
              />
              <button
                type="button"
                className="absolute inset-0 focus:outline-none"
              >
                <span className="sr-only">View details for {item.title}</span>
              </button>
            </div>

            <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">
              {item?.metadata?.firstName} {item?.metadata?.lastName}
            </p>
            <p className="pointer-events-none block text-xs truncate font-normal text-gray-500">
              {item?.metadata?.about}
            </p>
          </Link>
        </li>
      ))}
    </ul>
  );
}
