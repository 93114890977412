import SecondaryNavigation from "../components/SecondaryNavigation";
import { gql, useQuery } from "@apollo/client";
import React, { useState } from "react";

const title = "Team members";
const action = "Add new";

const GET_DIMENTIONS_QUERY = gql`
  query MyQuery($type: String) {
    dimentions(where: { type: { _eq: $type } }) {
      updated_at
      type
      name
      id
      created_at
      active
    }
    allDimentions: dimentions {
      updated_at
      type
      name
      id
      created_at
      active
    }
  }
`;

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Dimentions = () => {
  const [selectedType, setSelectedType] = useState("All"); // Track the selected type
  // highlight-start
  const { loading, error, data } = useQuery(GET_DIMENTIONS_QUERY, {
    variables: { type: selectedType }, // Pass the selected type as a variable
    //   variables: { id },
    //   skip: !id,
  });

  let dimensionsData = [];

  if (selectedType === "All") {
    dimensionsData = data?.allDimentions || [];
  } else {
    dimensionsData = data?.dimentions || [];
  }

  const secondaryNavigation = [
    { name: "All", href: "#", current: true, selected: selectedType === "All" },
    {
      name: "Division",
      href: "#",
      current: false,
      selected: selectedType === "Division",
    },
    {
      name: "Department",
      href: "#",
      current: false,
      selected: selectedType === "Department",
    },
    {
      name: "Location",
      href: "#",
      current: false,
      selected: selectedType === "Location",
    },
    {
      name: "Class",
      href: "#",
      current: false,
      selected: selectedType === "Class",
    },
  ];

  return (
    <>
      <main>
        <SecondaryNavigation
          title={title}
          action={action}
          secondaryNavigation={secondaryNavigation}
          setSelectedType={setSelectedType}
        />

        <div className="xl:space-y-20">
          {/* Recent activity table */}
          <div className="px-4 sm:px-6 lg:px-8 border rounded-md">
            <div className="flow-root pt-1">
              <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle">
                  <table className="min-w-full border-separate border-spacing-0">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                        >
                          Type
                        </th>
                        <th
                          scope="col"
                          className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                        >
                          Company
                        </th>
                        <th
                          scope="col"
                          className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dimensionsData?.map((item, itemIdx) => (
                        <tr key={item.id}>
                          <td
                            className={classNames(
                              itemIdx !== dimensionsData?.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {item.name}
                          </td>
                          <td
                            className={classNames(
                              itemIdx !== dimensionsData?.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap hidden px-3 py-4 text-sm text-gray-500 sm:table-cell"
                            )}
                          >
                            {item.type}
                          </td>
                          <td
                            className={classNames(
                              itemIdx !== dimensionsData?.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap hidden px-3 py-4 text-sm text-gray-500 lg:table-cell"
                            )}
                          >
                            {item.active}
                          </td>
                          <td
                            className={classNames(
                              itemIdx !== dimensionsData?.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                            )}
                          >
                            {item.active}
                          </td>

                          <td
                            className={classNames(
                              itemIdx !== dimensionsData?.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-8 lg:pr-8"
                            )}
                          >
                            <a
                              href="#"
                              className="text-indigo-600 hover:text-indigo-900"
                            >
                              Edit
                              <span className="sr-only">, {item.name}</span>
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Dimentions;
