import { gql, useQuery } from "@apollo/client";
import SimpleCard from "../components/SimpleCard";
import React, { useEffect } from "react";
import { useUserId } from "@nhost/react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

const GET_WINS_QUERY = gql`
  query MyQuery($id: uuid!) {
    wins(
      order_by: { updated_at: desc }
      where: { soul_id: { _eq: $id }, deleted: { _eq: false } }
    ) {
      updated_at
      title
      soul_id
      privacy
      id
      description
      deleted
      date
      created_at
      color
    }
  }
`;

const icon = "/trophy.svg";

export default function Wins() {
  const id = useUserId();
  const { loading, error, data, refetch } = useQuery(GET_WINS_QUERY, {
    variables: { id: id },
    skip: !{ id: id },
  });

  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <Helmet>
        <title>Wins</title>
      </Helmet>
      <div className="flex w-full max-w-4xl mx-auto h-full flex-grow">
        {data && (
          <SimpleCard
            items={data?.wins}
            icon={icon}
            className="bg-yellow-400"
          />
        )}
      </div>
    </>
  );
}
