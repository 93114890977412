import styles from "../styles/pages/Profile.module.css";
import { gql, useMutation } from "@apollo/client";
import { toast } from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useState } from "react";
import { Helmet } from "react-helmet";
import { useOutletContext } from "react-router-dom";
import Input from "../components/Input";
import { PhotographIcon, UserCircleIcon } from "@heroicons/react/solid";

const UPDATE_USER_MUTATION = gql`
  mutation (
    $id: uuid!
    $displayName: String!
    $avatarUrl: String!
    $metadata: jsonb
  ) {
    updateUser(
      pk_columns: { id: $id }
      _set: {
        displayName: $displayName
        metadata: $metadata
        avatarUrl: $avatarUrl
      }
    ) {
      id
      displayName
      metadata
      avatarUrl
    }
  }
`;

const Profile = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useOutletContext();
  const [displayName, setDisplayName] = useState(user?.displayName ?? "");
  const [firstName, setFirstName] = useState(user?.metadata?.firstName ?? "");
  const [lastName, setLastName] = useState(user?.metadata?.lastName ?? "");
  const [about, setAbout] = useState(user?.metadata?.about ?? "");
  const [description, setDescription] = useState(
    user?.metadata?.description ?? ""
  );
  const [backgroundImage, setBackgroundImage] = useState(
    user?.metadata?.backgroundImage ?? ""
  );
  const [avatarUrl, setAvatarUrl] = useState(user?.avatarUrl ?? "");

  const isFirstNameDirty = firstName !== user?.metadata?.firstName;
  const isbackgroundImageDirty =
    backgroundImage !== user?.metadata?.backgroundImage;
  const isLastNameDirty = lastName !== user?.metadata?.lastName;
  const isDisplayNameDirty = displayName !== user?.displayName;
  const isAvatarUrlDirty = avatarUrl !== user?.avatarUrl;
  const isAboutDirty = about !== user?.metadata?.about;
  const isProfileFormDirty =
    isFirstNameDirty ||
    isLastNameDirty ||
    isbackgroundImageDirty ||
    isAvatarUrlDirty ||
    isAboutDirty ||
    isDisplayNameDirty;

  const [mutateUser, { loading: updatingProfile }] =
    useMutation(UPDATE_USER_MUTATION);

  const updateUserProfile = async (e) => {
    e.preventDefault();

    try {
      await mutateUser({
        variables: {
          id: user.id,
          displayName: displayName,
          avatarUrl: avatarUrl,
          metadata: {
            firstName,
            lastName,
            backgroundImage,
            about,
            description,
          },
        },
      });

      toast.success("Updated successfully", { id: "updateProfile" });
      navigate(-1);
    } catch (error) {
      toast.error("Unable to update profile", { id: "updateProfile" });
    }
  };

  return (
    <>
      <Helmet>
        <title>My Personal Page - SpiritX</title>
        <meta
          name="description"
          content="Share your wins, thoughts, and experiences on your personal page on SpiritX. Inspire others, reflect on your journey, and drive positive change."
        />
        <meta
          name="keywords"
          content="personal page, SpiritX, wins, thoughts, experiences, inspire, reflect, positive change"
        />
        <meta property="og:title" content="My Personal Page - SpiritX" />
        <meta
          property="og:description"
          content="Share your wins, thoughts, and experiences on your personal page on SpiritX. Inspire others, reflect on your journey, and drive positive change."
        />
        {/* <meta
    property="og:image"
    content="https://example.com/personal-page-image.jpg"
  /> */}
      </Helmet>
      <div className="w-full ">
        <form
          onSubmit={updateUserProfile}
          className="max-w-2xl mx-auto px-2 sm:px-0"
        >
          <div className="space-y-12 w-full">
            <div className="border-b border-gray-900/10 pb-6">
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Profile
              </h2>
              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 w-full">
                <div className="sm:col-span-3">
                  <label
                    htmlFor="first-name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    First name
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="first-name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                      id="first-name"
                      autoComplete="given-name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Last name
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                      name="last-name"
                      id="last-name"
                      autoComplete="family-name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-4">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Email address
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      value={user?.email}
                      readOnly
                      type="email"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-4">
                  <label
                    htmlFor="username"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Username
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
                        spiritx.co/
                      </span>
                      <input
                        type="text"
                        value={displayName}
                        onChange={(e) => setDisplayName(e.target.value)}
                        name="username"
                        id="username"
                        autoComplete="username"
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-span-full">
                  <label
                    htmlFor="about"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    About
                  </label>
                  <div className="mt-2">
                    <textarea
                      id="about"
                      name="about"
                      value={about}
                      onChange={(e) => setAbout(e.target.value)}
                      rows={3}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  <p className="mt-3 text-sm leading-6 text-gray-600">
                    Write a few sentences about yourself.
                  </p>
                </div>

                <div className="sm:col-span-6">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Avatar URL
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      label="Avatar URL"
                      value={avatarUrl}
                      onChange={(e) => setAvatarUrl(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Background photo URL
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      label="Background URL"
                      value={backgroundImage}
                      onChange={(e) => setBackgroundImage(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                {/* <div className="col-span-full">
                <label
                  htmlFor="photo"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Photo
                </label>
                <div className="mt-2 flex items-center gap-x-3">
                  <UserCircleIcon
                    className="h-12 w-12 text-gray-300"
                    aria-hidden="true"
                  />
                  <button
                    type="button"
                    className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    Change
                  </button>
                </div>
              </div> */}

                {/* <div className="col-span-full">
                <label
                  htmlFor="cover-photo"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Cover photo
                </label>
                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                  <div className="text-center">
                    <PhotographIcon
                      className="mx-auto h-12 w-12 text-gray-300"
                      aria-hidden="true"
                    />
                    <div className="mt-4 flex text-sm leading-6 text-gray-600">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                      >
                        <span>Upload a file</span>
                        <input
                          id="file-upload"
                          name="file-upload"
                          type="file"
                          className="sr-only"
                        />
                      </label>
                      <p className="pl-1">or drag and drop</p>
                    </div>
                    <p className="text-xs leading-5 text-gray-600">
                      PNG, JPG, GIF up to 10MB
                    </p>
                  </div>
                </div>
              </div> */}
              </div>
            </div>
            <div className="border-b border-gray-900/10 pb-12">
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Privacy status
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                Your profile is{" "}
                {user?.phoneNumberVerified == false ? (
                  <span className="font-bold text-indigo-600">Private</span>
                ) : (
                  <span className="font-bold text-green-600">Public</span>
                )}
              </p>
              <p className="mt-1 text-sm leading-6 text-gray-700">
                If you would like to change the privacy of your profile, please
                write to <span className="font-bold">support@spiritx.co</span>
              </p>
            </div>
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            {/* <button
              type="button"
              className="text-sm font-semibold leading-6 text-gray-900"
              onClick={navigate(-1)}
            >
              Cancel
            </button> */}
            <button
              type="submit"
              disabled={!isProfileFormDirty}
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Save
            </button>
          </div>
        </form>
      </div>

      {/* <div className={styles.container}>
        <div className={styles.info}>
          <h2>Profile</h2>
          <p>Update your personal information.</p>
        </div>

        <div>
          <form onSubmit={updateUserProfile}>
            <div className="">
              <div className={styles["input-group"]}>
                <Input
                  type="text"
                  label="First name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
                <Input
                  type="text"
                  label="Last name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </div>
              <div className={styles["input-group"]}>
                <Input
                  type="text"
                  label="Background URL"
                  value={backgroundImage}
                  onChange={(e) => setBackgroundImage(e.target.value)}
                />
              </div>
              <div className={styles["input-group"]}>
                <Input
                  type="text"
                  label="Avatar URL"
                  value={avatarUrl}
                  onChange={(e) => setAvatarUrl(e.target.value)}
                />
              </div>
              <div className={styles["input-group"]}>
                <Input
                  type="text"
                  label="displayName"
                  value={displayName}
                  onChange={(e) => setDisplayName(e.target.value)}
                />
              </div>
              <div className={styles["input-email-wrapper"]}>
                <Input
                  type="email"
                  label="Email address"
                  value={user?.email}
                  readOnly
                />
              </div>
            </div>

            <div className={styles["form-footer"]}>
              <button
                type="submit"
                disabled={!isProfileFormDirty}
                className={styles.button}
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </div> */}
    </>
  );
};

export default Profile;
